
import React, { useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// import Fingerprint from "@material-ui/icons/Fingerprint";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
// import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";

// import Typography from '@material-ui/core/Typography';
import styles from "assets/jss/material-kit-react/views/loginPage.js";
// import { Link } from "react-router-dom";
// import CreateIcon from '@material-ui/icons/Create';
// import CollectionsIcon from '@material-ui/icons/Collections';
// import MintArtToken from 'views/MintTokenPage/Components/MintArtToken';
// import MintTicketToken from 'views/MintTokenPage/Components/MintTicketToken';
const useStyles = makeStyles(styles);

function MintTokenCompleted(props) {
  const classes = useStyles();

  return (
    <div >
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12} >

          <Card >
            <CardHeader color="info" className={classes.cardHeader}>
              <h3>Congrats! You have successfully created you token.</h3>
            </CardHeader>
            {/* <p className={classes2.divider}>Help us understand your token's nature</p>
            <CardBody>
            </CardBody> */}
            <CardFooter>
              <Button
                onClick={() => { props.setStep("REALM") }}
                className={classes.backButton}
              > Mint More Tokens</Button>
              <Button variant="contained" color="info" 
              onClick={() => { props.history.push("/") }} 
               >View My Tokens
              </Button>
            </CardFooter>

          </Card>

        </GridItem>
      </GridContainer>
    </div>

  );
}

export default MintTokenCompleted