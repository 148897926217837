/* eslint-disable */
import React, { useEffect, useState } from 'react'
// @material-ui/core
import { makeStyles } from '@material-ui/core/styles'
// core components
import GridItem from 'components/Grid/GridItem.js'
import GridContainer from 'components/Grid/GridContainer.js'
import TokenMorpher from 'views/VaultList/Components/TokenMorpher'
import DummyToken from 'views/VaultList/Components/DummyToken'
import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js'
import { getUserSession } from 'services/UserManagement'
import {
  getTokenByIdList,
  getTokenBalanceFromStellar
} from 'services/TokenManagement'
// import Paginations from "components/Pagination/Pagination.js";
import Pagination from '@material-ui/lab/Pagination'
// import Grid from '@material-ui/core/Grid';
import { withRouter, Link } from 'react-router-dom'
import Card from 'components/Card/Card.js'
// import CardBody from "components/Card/CardBody.js";
import CardHeader from 'components/Card/CardHeader.js'
import Web3 from 'web3'
import { web3Providers } from 'variables/constants'

const useStyles = makeStyles(styles)

// const ethContractList = [
//   '0x384C818e7EfF21942C0150f2EB484723295cD9eE',
//   '0x2f3e95204578fa1B3F5bf9EabC92E9A2eb5fA2aD'
// ]
const bscContractList = [
  '0x239F16F0F107C0AfCB39cF1a9d57Be2318C1A409',
  '0x3280d8E1d5b055542d1367A440c375e54390b13D'
]

function VaultList (props) {
  const classes = useStyles()
  const [user, setUser] = useState(getUserSession())
  const [tokens, setTokens] = useState([])
  const [paginatedTokens, setPaginatedTokens] = useState([])
  const [loading, setloading] = useState(false)
  const [pageCount, setPageCount] = useState(0)
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(12)
  const [loadingText, setLoadingText] = useState('loading')
  const [placeholder, setPlaceholder] = useState([
    0,
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11
  ])

  const [tradable, setTradable] = useState(false)
  useEffect(() => {
    const fetchData = async () => {
      setloading(true)

      const key = user.accounts[0].publicKey
      setLoadingText('Retrieving Tokens From Multiple Blockchains')
      let IdList = []
      if (user.accounts && user.accounts.length >= 3) {
        const ethereumKey = user.accounts[2].publicKey
        const ERC721ABI = require('./ERC721.json')
        //bsc
        for (let i = 0; i < bscContractList.length; i++) {
          var bscWeb3 = new Web3(web3Providers.providerBsc)
          const contract1 = new bscWeb3.eth.Contract(
            ERC721ABI,
            bscContractList[i]
          )
          const tokenCount = await contract1.methods
            .balanceOf(ethereumKey)
            .call({ from: ethereumKey })
          // console.log(tokenCount);

          for (let j = 0; j < tokenCount; j++) {
            const tokenId = await contract1.methods
              .tokenOfOwnerByIndex(ethereumKey, j)
              .call({ from: ethereumKey })
            IdList.push({
              issuer: bscContractList[i],
              id: tokenId,
              blockchain: 'BSCTESTNET'
            })
          }
        }
        // for (let k = 0; k < ethContractList.length; k++) {
        //   var ethWeb3 = new Web3(web3Providers.providerRinkeby)

        //   const contract = new ethWeb3.eth.Contract(
        //     ERC721ABI,
        //     ethContractList[k]
        //   )
        //   const tokenCount2 = await contract.methods
        //     .balanceOf(ethereumKey)
        //     .call({ from: ethereumKey })
        //   // console.log(tokenCount);

        //   for (let l = 0; l < tokenCount2; l++) {
        //     const tokenId = await contract.methods
        //       .tokenOfOwnerByIndex(ethereumKey, l)
        //       .call({ from: ethereumKey })
        //     IdList.push({
        //       issuer: ethContractList[k],
        //       id: tokenId,
        //       blockchain: 'RINKEBY'
        //     })
        //   }
        // }
      }

      const balances = await getTokenBalanceFromStellar(key)
      if (balances != null) {
        //console.log(balances)
        balances.forEach(token => {
          IdList.push({
            id: token.assetCode,
            issuer: token.issuer,
            blockchain: 'STELLAR'
          })
        })
      }

      console.log(IdList)
      setLoadingText('Retrieving MetaData From Niftron')
      let result = await getTokenByIdList(IdList)
      if (result != null) {
        if (result.data && result.data.length > 0) {
          if (pageCount != result.data.length / limit) {
            setPageCount(Math.ceil(result.data.length / limit))
          }
          setTokens(result.data)
        }
        setloading(false)
      }
    }
    fetchData()
  }, [])
  useEffect(() => {
    const fetchData = async () => {
      setPaginatedTokens(
        tokens.slice(limit * (page - 1), limit * (page - 1) + limit)
      )
    }
    fetchData()
  }, [page, limit, tokens])
  return (
    <div>
      {loading && (
        <GridContainer justify='center' style={{ minHeight: 500 }}>
          {placeholder.map(i => (
            <GridItem
              key={i}
              xs={12}
              sm={6}
              md={4}
              lg={3}
              className={'loadingCard'}
            >
              <DummyToken loadingText={loadingText} />
            </GridItem>
          ))}
        </GridContainer>
      )}
      {!loading && paginatedTokens.length > 0 && (
        <GridContainer justify='center' style={{ minHeight: 500 }}>
          {paginatedTokens.map((item, index) =>
            item.data ? (
              <GridItem key={index} xs={12} sm={6} md={4} lg={3}>
                <Link to={'/token/' + item.data._id}>
                  <TokenMorpher item={item.data} />
                </Link>
              </GridItem>
            ) : null
          )}
        </GridContainer>
      )}
      {tokens.length > 0 && (
        <GridContainer justify='center'>
          <GridItem xs={12} sm={12} md={12}>
            <Pagination
              count={pageCount}
              color='info'
              style={{ float: 'right' }}
              onChange={(e, number) => {
                //console.log(number)
                setPage(number)
              }}
            />
          </GridItem>
        </GridContainer>
      )}
      {!loading && tokens.length === 0 && (
        <GridContainer justify='center'>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color='info' className={classes.cardHeader}>
                <h3>Sorry! your vault is empty.</h3>
              </CardHeader>
            </Card>
          </GridItem>
        </GridContainer>
      )}
    </div>
  )
}

export default withRouter(VaultList)
