
import axios from "axios";
import { niftronTokenLambda } from "variables/constants";
import { NiftronIssuerPublicKey } from "variables/constants";
import { Server } from "stellar-sdk";
import store from "redux/reducers"

let StellarUrl = store.getState().StellarUrl
let StellarUrlTest = store.getState().StellarUrlTest

store.subscribe(() => {
  StellarUrl = store.getState().StellarUrl
  StellarUrlTest = store.getState().StellarUrlTest
})

export async function AcceptApproval(id, transferModel) {
  try {
    // let token;
    // if (localStorage.getItem("token") != null) {
    //   token = localStorage.getItem("token");
    // }
    let postBody = transferModel
    const res = await axios.post(`${niftronTokenLambda}/approvals/${id}/accept`, postBody, {
      headers: {
        // 'Authorization': "bearer " + token,
        "Content-Type": "application/json"
      }
    });

    if (res === null) {
      return null
    }
    return res.status;
  } catch (err) {
    return null;
  }
}
export async function RejectApproval(id, transferModel) {
  try {
    // let token;
    // if (localStorage.getItem("token") != null) {
    //   token = localStorage.getItem("token");
    // }
    let postBody = transferModel

    const res = await axios.post(`${niftronTokenLambda}/approvals/${id}/reject`, postBody, {
      headers: {
        // 'Authorization': "bearer " + token,
        "Content-Type": "application/json"
      }
    });
    if (res === null) {
      return null
    }
    return res.status;
  } catch (err) {
    return null;
  }
}
export async function ListApprovalsBySigner(id, limit, page) {
  try {
    // let token;
    // if (localStorage.getItem("token") != null) {
    //   token = localStorage.getItem("token");
    // }
    let query = ""
    if (limit > 0 && page > 0) {
      query += `limit=${limit}&page=${page}`
    }
    const res = await axios.get(`${niftronTokenLambda}/users/${id}/approvals?${query}`, {
      headers: {
        // 'Authorization': "bearer " + token,
        "Content-Type": "application/json"
      }
    });

    if (res === null) {
      return null
    }
    return res.data;
  } catch (err) {
    return null;
  }
}


export async function AcceptTransfer(id, transferModel) {
  try {
    // let token;
    // if (localStorage.getItem("token") != null) {
    //   token = localStorage.getItem("token");
    // }
    let postBody = transferModel
    const res = await axios.post(`${niftronTokenLambda}/transfers/${id}/accept`, postBody, {
      headers: {
        // 'Authorization': "bearer " + token,
        "Content-Type": "application/json"
      }
    });

    if (res === null) {
      return null
    }
    return res.status;
  } catch (err) {
    return null;
  }
}
export async function RejectTransfer(id, transferModel) {
  try {
    // let token;
    // if (localStorage.getItem("token") != null) {
    //   token = localStorage.getItem("token");
    // }
    let postBody = transferModel

    const res = await axios.post(`${niftronTokenLambda}/transfers/${id}/reject`, postBody, {
      headers: {
        // 'Authorization': "bearer " + token,
        "Content-Type": "application/json"
      }
    });
    if (res === null) {
      return null
    }
    return res.status;
  } catch (err) {
    return null;
  }
}
export async function getTransfersBySender(id, limit, page) {
  try {
    // let token;
    // if (localStorage.getItem("token") != null) {
    //   token = localStorage.getItem("token");
    // }
    let query = ""
    if (limit > 0 && page > 0) {
      query += `limit=${limit}&page=${page}`
    }
    const res = await axios.get(`${niftronTokenLambda}/transfers/${id}/sender?${query}`, {
      headers: {
        // 'Authorization': "bearer " + token,
        "Content-Type": "application/json"
      }
    });

    if (res === null) {
      return null
    }
    return res.data;
  } catch (err) {
    return null;
  }
}
export async function getTransfersByReceiver(id, limit, page) {
  try {
    // let token;
    // if (localStorage.getItem("token") != null) {
    //   token = localStorage.getItem("token");
    // }
    let query = ""
    if (limit > 0 && page > 0) {
      query += `limit=${limit}&page=${page}`
    }
    const res = await axios.get(`${niftronTokenLambda}/transfers/${id}/receiver?${query}`, {
      headers: {
        // 'Authorization': "bearer " + token,
        "Content-Type": "application/json"
      }
    });

    if (res === null) {
      return null
    }
    return res.data;
  } catch (err) {
    return null;
  }
}
export async function getTransferById(id) {
  try {
    // let token;
    // if (localStorage.getItem("token") != null) {
    //   token = localStorage.getItem("token");
    // }
    const res = await axios.get(`${niftronTokenLambda}/transfers/${id}`, {
      headers: {
        // 'Authorization': "bearer " + token,
        "Content-Type": "application/json"
      }
    });
    if (res === null) {
      return null
    }
    return res.data;
  } catch (err) {
    return null;
  }
}
