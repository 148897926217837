
import React, { useState, useCallback, useRef} from "react";

import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// import watermark from "watermarkjs";
// core components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
// import CardAvatar from "components/Card/CardAvatar";
import CardBody from "components/Card/CardBody";
import { CardMedia } from '@material-ui/core';
import sha256 from "sha256";

import CardFooter from "components/Card/CardFooter";
import InputAdornment from "@material-ui/core/InputAdornment";
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
// import Id from "@material-ui/icons/Email";
// import PermIdentityIcon from '@material-ui/icons/PermIdentity';
// import FaceIcon from '@material-ui/icons/Face';
// import PhoneIcon from '@material-ui/icons/Phone';
// import HomeIcon from '@material-ui/icons/Home';
// import LocationCityIcon from '@material-ui/icons/LocationCity';
import PublicIcon from '@material-ui/icons/Public';
import CustomInputSelect from "components/CustomInputSelect/CustomInputSelect";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';

import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Dropzone from 'react-dropzone';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { Link } from "react-router-dom";
import { getUserSession, decryptSecret } from "services/UserManagement"
import { addTicket } from "services/TokenManagement"
import CropFreeIcon from '@material-ui/icons/CropFree';
import Fab from '@material-ui/core/Fab';

import { BuildArtNFTXDR } from "services/XDRBuilder"
import { BuildNiftronId } from "services/NiftronBuilder"
import Icon from "@material-ui/core/Icon";

import imageCompression from 'browser-image-compression';
// import IPFS from 'ipfs';
import { useSnackbar } from 'notistack';

import styles from "assets/jss/material-kit-react/views/loginPage";
import { Keypair } from "stellar-sdk";

import ColorPicker from 'material-ui-color-picker'
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  // KeyboardTimePicker,
  // KeyboardDatePicker,
  KeyboardDateTimePicker
} from '@material-ui/pickers';

const useStyles2 = makeStyles(styles);
const useStyles = makeStyles((theme) => ({
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: 'inline-block',
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));


export default function MintTicketToken(props) {
  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();
  const classes2 = useStyles2();

  const [user, setUser] = useState(getUserSession())
  const [activeStep, setActiveStep] = React.useState(0);

  //basic details
  const [tokenName, setTokenName] = useState("");
  const [tokenType, setTokenType] = useState("SFT");
  const [assetRealm, setAssetRealm] = useState(props.realm);
  const [tradable, setTradable] = useState(false);
  const [category, setCategory] = useState(props.category);
  //basic details error
  const [tokenNameError, setTokenNameError] = useState(false);
  const [tokenTypeError, setTokenTypeError] = useState(false);
  const [assetRealmError, setAssetRealmError] = useState(false);
  const [tradableError, setTradableError] = useState(false);
  const [categoryError, setCategoryError] = useState(false);


  //token specific details
  //ticket
  const [file, setFile] = useState(null)
  const [fileWM, setFileWM] = useState(null)
  const [price, setPrice] = useState(0);
  const [assetCount, setAssetCount] = useState(1);

  const [openCrop, setOpenCrop] = React.useState(false);
  const imgRef = useRef(null);
  const [crop, setCrop] = useState({ unit: '%', width: 30, aspect: 16 / 9 });
  const [eventName, setEventName] = useState("");
  const [edition, setEdition] = useState("");
  const [eventDate, handleDateChange] = useState(new Date());
  const [location, setLocation] = useState("");
  const [textColor, setTextColor] = useState("");
  const [backgroundColor, setBackgroundColor] = useState("");

  //ticket error
  const [fileError, setFileError] = useState(false)
  const [fileWMError, setFileWMError] = useState(false)
  const [priceError, setPriceError] = useState(false);
  const [assetCountError, setAssetCountError] = useState(false);
  const [eventNameError, setEventNameError] = useState(false);
  const [editionError, setEditionError] = useState(false);
  const [dateError, setDateError] = useState(false);
  const [locationError, setLocationError] = useState(false);


  //Confirm 
  const [secret, setSecret] = useState("");
  //Confirm error
  const [secretError, SecretKeyError] = useState(false);

  const [loading, setLoading] = useState(false);
  const [loadingMessage, setloadingMessage] = useState("please remain patient");

  const [hoverPassword, setHoverPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [linkStyle, setLinkStyle] = useState({ cursor: 'pointer' });

  const [open, setOpen] = React.useState(false);



  const getSteps = () => {
    return ['Token Details', 'Ticket Details'];
  }

  const steps = getSteps();
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (activeStep === 0) {
      props.setStep("CATEGORY")
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleCropOpen = () => {
    setOpenCrop(true);
  };
  const handleCropClose = () => {
    setOpenCrop(false);
  };
  const onLoad = useCallback(img => {
    imgRef.current = img;
  }, []);

  const makeClientCrop = async crop => {
    if (imgRef.current && crop.width && crop.height) {
      createCropPreview(imgRef.current, crop, 'newFile.jpeg');
    }
  };

  const createCropPreview = async (image, crop, fileName) => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          reject(new Error('Canvas is empty'));
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(fileWM);
        setFileWM(window.URL.createObjectURL(blob));
      }, 'image/jpeg');
    });
  };




  const Basic = (event) => {
    event.preventDefault()
    //required check
    setTokenNameError(tokenName === "" ? true : false);
    setTokenTypeError(tokenType === "" ? true : false);
    setAssetRealmError(assetRealm === "" ? true : false);
    setCategoryError(category === "" ? true : false);
    if (tokenName != "" && tokenType != "" && category != "" && assetRealm != "") {
      handleNext()
    }
  };

  const SpecificTicket = (event) => {
    event.preventDefault()
    //required check
    setFileError(file === null ? true : false);
    setFileWMError(fileWM === null ? true : false);
    setPriceError(price < 0 && tradable ? true : false);
    setAssetCountError(!assetCount || assetCount < 1 ? true : false);
    setEventNameError(eventName === "" ? true : false);
    setEditionError(edition === "" ? true : false);
    setLocationError(location === "" ? true : false);

    if (file != null && fileWM != null && price > -1 && assetCount > -1
      && eventName != "" && edition != "" && location != "") {
      handleNext()
    }
  };

  const submitTicket = async (e) => {
    e.preventDefault()

    setLoading(true)
    setOpen(false);

    setloadingMessage("Verifying User Credentials ...")
    let secretKey = secret
    if (user.authType != "2") {
      if (decryptSecret(user.encryptedSecret, secret) != null) {
        secretKey = decryptSecret(user.encryptedSecret, sha256(secret))
      }
    }


    const niftronID = BuildNiftronId(tokenType, tradable, tokenName, eventName)
    //console.log(niftronID)

    setloadingMessage("Building Blockchain XDR ...")
    const creatorKeypair = Keypair.fromSecret(secretKey);
    let IssuerPublicKey = user.publicKey
    if (!tradable) {
      user.accounts.forEach((account) => {
        if (account.accountType === "1") {
          IssuerPublicKey = account.publicKey
        }
      })
    }



    const xdr = await BuildArtNFTXDR(IssuerPublicKey, niftronID, creatorKeypair, tradable, assetCount)

    setloadingMessage("Storing Meta Data to Niftron ...")

    const serverRes = await addTicket({
      _id: niftronID,
      tokenName: tokenName,
      tokenType: tokenType,
      assetRealm: assetRealm,
      tradable: tradable,
      category: category,
      assetCode: niftronID,
      assetIssuer: IssuerPublicKey,
      assetCount: assetCount,
      previewUrl: fileWM,
      price: price,
      eventName, edition, eventDate, location, textColor, backgroundColor,
      xdr: xdr,
    })

    if (serverRes != null) {
      //console.log(serverRes)
      switch (serverRes) {
        case 200: enqueueSnackbar("Token Minted Successfully", { variant: "success" });
          props.setStep("COMPLETED"); break;
        case 201: enqueueSnackbar("Token Name is already taken", { variant: "warning" }); break;
        case 202: enqueueSnackbar("Insufficient Fund in Account", { variant: "warning" }); break;
        case null: enqueueSnackbar("Token Minting failed", { variant: "error" });
      }

    }
    setLoading(false)

  }


  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <div className={classes.root}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <div>
              {activeStep === steps.length ? (
                <div>
                  <Typography className={classes.instructions}>Confirm Your Token Details</Typography>
                  <Dialog
                    maxWidth="sm"
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                    scroll={"body"}
                  >
                    <DialogTitle id="responsive-dialog-title">{"Please Confirm Action"}</DialogTitle>
                    <DialogContent dividers>
                      <form style={loading ? {
                        filter: "blur(1px)",
                        "-webkit-filter": "blur(1px)"
                      } : null} onSubmit={submitTicket}>
                        <CustomInput
                          error={secretError}
                          labelText={user.authType === "2" ? "Secret Key *" : "Password *"}
                          id="tokenName"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            type: !showPassword ? "password" : "text",
                            endAdornment: (
                              <InputAdornment position="end">
                                {hoverPassword &&
                                  <Icon style={linkStyle} onMouseEnter={() => { setHoverPassword(true) }} onMouseLeave={() => { setHoverPassword(false) }}
                                    className={classes.inputIconsColor} onClick={() => {
                                      setShowPassword(!showPassword)

                                    }}>
                                    {showPassword ? "visibilityoff" : "visibility"}</Icon>
                                }

                                {!hoverPassword &&
                                  <Icon style={linkStyle} className={classes.inputIconsColor}
                                    onMouseEnter={() => { setHoverPassword(true) }} onMouseLeave={() => { setHoverPassword(false) }}>
                                    lock</Icon>
                                }
                              </InputAdornment>
                            ), required: true,
                            onChange: function (e) {
                              setSecret(e.target.value)
                              SecretKeyError(e.target.value === "" ? true : false)
                            }, value: secret
                          }}
                        />
                        {user.authType === "2" && <>
                          <Grid
                            container
                            spacing={0}
                            direction="column"
                            alignItems="center"
                            justify="center"
                          >

                            <Grid item xs={3}>
                              or                  </Grid>

                          </Grid>

                          <Dropzone onDrop={acceptedFiles => {
                            const reader = new FileReader()
                            reader.addEventListener("load", async () => {
                              // setFile(reader.result)
                              setSecret(JSON.parse(atob(reader.result)).secretKey)
                            });
                            reader.readAsText(acceptedFiles[0])
                          }}
                            multiple={false} accept={[".niftron"]}>
                            {({ getRootProps, getInputProps }) => (
                              <Link >
                                <section className={"container"}>
                                  <div {...getRootProps({ className: fileError ? 'dropzone2Error' : 'dropzone2' })}>
                                    <input {...getInputProps()} />
                                    <p>Drop or select your niftron credential file</p>

                                  </div>
                                </section>
                              </Link>
                            )}
                          </Dropzone></>
                        }
                        <Button color={"info"} onClick={submitTicket}
                          type={"submit"} disabled={loading}>Confirm</Button>
                      </form>
                    </DialogContent>
                  </Dialog>
                  <Card>
                    <CardBody>
                      <GridContainer justify="center">
                        <GridItem xs={12} sm={6} md={6}>
                          <GridContainer justify="center">
                            <GridItem xs={12} sm={12} md={12} lg={12} >
                              <div type="ticket" class="widget --flex-column" >
                                <div class="top --flex-column" style={{ backgroundColor: backgroundColor, color: textColor }}>
                                  <div class="bandname -bold">{eventName != "" ? eventName : "Event Name"}</div>
                                  <div class="tourname">{edition != "" ? edition : "Edition or Series"}</div>
                                  <Link onClick={handleCropOpen}>
                                    <div class="container">
                                      <CardMedia style={{ height: 140 }} image={fileWM} />
                                      <div class="overlay">
                                        <div class="text">Resize<CropFreeIcon /></div>
                                      </div>
                                    </div>
                                  </Link>
                                  <GridContainer  >
                                    <GridItem xs={8} sm={8} md={8} lg={8}>
                                      <div class="date">{eventDate ? eventDate.toDateString() : "Date"}</div>
                                      <div class="date">{eventDate ? eventDate.toLocaleTimeString() : "Time"}</div>

                                      <div class="location -bold">{location != "" ? location : "Location"}</div>
                                    </GridItem>
                                    <GridItem xs={4} sm={4} md={4} lg={4}>
                                      <div class="label">Price</div>
                                      <div class="cost -bold">${price}</div>
                                    </GridItem>
                                    {/* <GridItem xs={12} sm={12} md={12} lg={12}>
                                            <div class="date">{eventDate ? eventDate.toLocaleTimeString() : "Time"}</div>
                                            </GridItem> */}

                                  </GridContainer>
                                </div>
                                <div class="rip" style={{ backgroundColor: backgroundColor, color: textColor }}></div>
                                <div class="bottom --flex-row-j!sb" style={{ backgroundColor: backgroundColor, color: textColor }}>
                                  {BuildNiftronId(tokenType, tradable, tokenName, file)}
                                </div>
                              </div >


                            </GridItem></GridContainer>
                        </GridItem>
                        <GridItem xs={12} sm={6} md={6}>
                          <Typography variant="body1">
                            Niftron Id: {BuildNiftronId(tokenType, tradable, tokenName, file)}
                          </Typography>
                          <Typography variant="body1">
                            Token Name: {tokenName}
                          </Typography>
                          <Typography variant="body1">
                            Token Type: {tokenType}
                          </Typography>
                          <Typography variant="body1">
                            Asset Realm: {assetRealm}
                          </Typography>
                          <Typography variant="body1">
                            Category: {category}
                          </Typography>
                          <Typography variant="body1">
                            Tradable: {tradable ? "Yes" : "No"}
                          </Typography>
                          <Typography variant="body1">
                            Price: ${price}
                          </Typography>
                          <Typography variant="body1">
                            No of Tickets: {assetCount}
                          </Typography>
                          <Typography variant="subtitle1">
                            {loading && loadingMessage}                {loading && <CircularProgress />}

                          </Typography>
                        </GridItem>
                      </GridContainer>
                    </CardBody>
                  </Card>
                  <Button onClick={handleReset}>Make a Change</Button>
                  <Button color={"info"} onClick={handleOpen}>{tradable ? "Create and Publish" : "Create"}</Button>

                </div>
              ) : (
                  <div>
                    <Typography className={classes.instructions}>
                      {activeStep === 0 &&
                        <GridContainer justify="center">
                          <GridItem xs={12} sm={12} md={12}>
                            <Card >
                              <form className={classes2.form} style={loading ? {
                                filter: "blur(1px)",
                                "-webkit-filter": "blur(1px)"
                              } : null} onSubmit={Basic}>
                                <CardHeader color="info" className={classes2.cardHeader}>
                                  <h3>Token Details</h3>
                                </CardHeader>
                                <p className={classes2.divider}>Help us understand your token's nature</p>
                                <CardBody>
                                  <GridContainer justify="center">
                                    <GridItem xs={12} sm={12} md={6} lg={6}>
                                      <CustomInput
                                        error={tokenNameError}
                                        labelText="Token Name *"
                                        id="tokenName"
                                        formControlProps={{
                                          fullWidth: true
                                        }}
                                        inputProps={{
                                          type: "text",
                                          // endAdornment: (
                                          //   <InputAdornment position="end">
                                          //     <PermIdentityIcon className={classes2.inputIconsColor} />
                                          //   </InputAdornment>
                                          // ), 
                                          required: true,
                                          onChange: function (e) {
                                            setTokenName(e.target.value)
                                            setTokenNameError(e.target.value === "" ? true : false)
                                          }, value: tokenName
                                        }}
                                      />
                                    </GridItem>
                                  </GridContainer>
                                  <GridContainer>
                                    <GridItem xs={12} sm={12} md={4} lg={4}>
                                      <CustomInputSelect
                                        error={tokenTypeError}
                                        options={<Select disabled
                                          value={tokenType}
                                          onChange={(e) => {
                                            setTokenType(e.target.value)
                                            setTokenTypeError(e.target.value === "" ? true : false)
                                            if (e.target.value === "SFT") {
                                              setAssetCount(1)
                                            }
                                          }}
                                          displayEmpty
                                          className={classes2.selectEmpty}>
                                          <MenuItem value="">
                                            <em>Choose Your Token Type</em>
                                          </MenuItem>
                                          <MenuItem value={"NFT"}>Non-Fungible Token</MenuItem>
                                          <MenuItem value={"SFT"}>Semi-Fungible Token</MenuItem>
                                        </Select>}
                                        labelText="Token Type *"
                                        id="tokenType"
                                        formControlProps={{
                                          fullWidth: true
                                        }}
                                        inputProps={{
                                          type: "text",
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              <PublicIcon className={classes2.inputIconsColor} />
                                            </InputAdornment>
                                          ), required: true
                                        }} />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4} lg={4}>
                                      <CustomInputSelect
                                        error={assetRealmError}
                                        options={<Select disabled
                                          value={assetRealm}
                                          onChange={(e) => {
                                            setAssetRealm(e.target.value)
                                            setAssetRealmError(e.target.value === "" ? true : false)
                                          }}
                                          displayEmpty
                                          className={classes2.selectEmpty}>
                                          <MenuItem value="">
                                            <em>Choose Your Asset Realm</em>
                                          </MenuItem>
                                          <MenuItem value={"Digital"}>Digital Realm</MenuItem>
                                          <MenuItem value={"Physical"} disabled>Physical Realm</MenuItem>
                                        </Select>}
                                        labelText="Asset Realm *"
                                        iD="assetRealm"
                                        formControlProps={{
                                          fullWidth: true
                                        }}
                                        inputProps={{
                                          type: "text",
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              <PublicIcon className={classes2.inputIconsColor} />
                                            </InputAdornment>
                                          ), required: true
                                        }} />
                                    </GridItem>

                                    <GridItem xs={12} sm={12} md={4} lg={4}>
                                      <CustomInputSelect
                                        //info
                                        error={categoryError}
                                        options={<Select disabled
                                          value={category}
                                          onChange={(e) => {
                                            setCategory(e.target.value)
                                            setCategoryError(e.target.value === "" ? true : false)
                                          }}
                                          displayEmpty
                                          className={classes2.selectEmpty}>
                                          <MenuItem value="">
                                            <em>Choose Your Category</em>
                                          </MenuItem>
                                          {assetRealm === "Digital" &&
                                            <MenuItem value={"DigitalArt"}>Digital Art</MenuItem>
                                          }
                                          {assetRealm === "Digital" &&
                                            <MenuItem value={"Ticket"}>Ticket</MenuItem>
                                          }
                                          {assetRealm === "Digital" &&
                                            <MenuItem value={"Domain"}>Domain Names</MenuItem>
                                          }                                        </Select>}
                                        labelText="Category *"
                                        id="category"
                                        formControlProps={{
                                          fullWidth: true
                                        }}
                                        inputProps={{
                                          type: "text",
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              <PublicIcon className={classes2.inputIconsColor} />
                                            </InputAdornment>
                                          ), required: true
                                        }} />
                                    </GridItem>
                                  </GridContainer>
                                  <GridContainer >
                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                      <Grid component="label" container alignItems="center" spacing={1}>
                                        <Grid item>Non-tradable</Grid>
                                        <Grid item>
                                          <Switch
                                            color={"primary"}
                                            checked={tradable}
                                            onChange={(e) => {
                                              setTradable(e.target.checked)
                                              if (!e.target.checked) {
                                                setPrice(0)
                                              }

                                            }}
                                            name="Tradable"
                                          />
                                        </Grid>
                                        <Grid item>Tradable</Grid>
                                      </Grid>

                                    </GridItem>
                                  </GridContainer>
                                  {loading && <LinearProgress />}
                                </CardBody>
                                <CardFooter>
                                  <Button
                                    // disabled={activeStep === 0}
                                    onClick={handleBack}
                                    className={classes.backButton}
                                  > Back</Button>
                                  <Button variant="contained" color="info" onClick={Basic} type={"submit"}
                                    disabled={false}>
                                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                  </Button>
                                </CardFooter>
                              </form>
                            </Card>
                          </GridItem>
                        </GridContainer>}
                      {activeStep === 1 && <GridContainer justify="center">
                        <GridItem xs={12} sm={12} md={12}>
                          <Card >
                            <form className={classes2.form} style={loading ? {
                              filter: "blur(1px)",
                              "-webkit-filter": "blur(1px)"
                            } : null} onSubmit={SpecificTicket}>
                              <CardHeader color="info" className={classes2.cardHeader}>
                                <h3>Ticket Details</h3>
                              </CardHeader>
                              {category === "TICKET" &&
                                <CardBody>
                                  <GridContainer justify="center">

                                    <GridItem xs={6} sm={8} md={8} lg={9}>
                                      <GridContainer justify="center">
                                        <GridItem xs={12} sm={12} md={6} lg={6}>
                                          <CustomInput
                                            error={eventNameError}
                                            labelText="Event Name *"
                                            id="eventName"
                                            formControlProps={{
                                              fullWidth: true
                                            }}
                                            inputProps={{
                                              type: "text",
                                              // endAdornment: (
                                              //   <InputAdornment position="end">
                                              //     <PermIdentityIcon className={classes2.inputIconsColor} />
                                              //   </InputAdornment>
                                              // ), 
                                              required: true,
                                              onChange: function (e) {
                                                setEventName(e.target.value)
                                                setEventNameError(e.target.value === "" ? true : false)
                                              }, value: eventName
                                            }}
                                          />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6} lg={6}>
                                          <CustomInput
                                            error={editionError}
                                            labelText="Edition *"
                                            id="edition"
                                            formControlProps={{
                                              fullWidth: true
                                            }}
                                            inputProps={{
                                              type: "text",
                                              // endAdornment: (
                                              //   <InputAdornment position="end">
                                              //     <PermIdentityIcon className={classes2.inputIconsColor} />
                                              //   </InputAdornment>
                                              // ), 
                                              required: true,
                                              onChange: function (e) {
                                                setEdition(e.target.value)
                                                setEditionError(e.target.value === "" ? true : false)
                                              }, value: edition
                                            }}
                                          />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                          <CustomInput
                                            error={locationError}
                                            labelText="Location *"
                                            id="location"
                                            formControlProps={{
                                              fullWidth: true
                                            }}
                                            inputProps={{
                                              type: "text",
                                              // endAdornment: (
                                              //   <InputAdornment position="end">
                                              //     <PermIdentityIcon className={classes2.inputIconsColor} />
                                              //   </InputAdornment>
                                              // ), 
                                              required: true,
                                              onChange: function (e) {
                                                setLocation(e.target.value)
                                                setLocationError(e.target.value === "" ? true : false)
                                              }, value: location
                                            }}
                                          />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6} lg={6}>
                                          <Dropzone onDrop={async acceptedFiles => {
                                            const options = {
                                              maxSizeMB: 0.1,
                                              maxWidthOrHeight: 500,
                                              useWebWorker: true,
                                              fileType: 'png'
                                            }
                                            const compressedFile = await imageCompression(acceptedFiles[0], options);
                                            const reader = new FileReader()
                                            reader.addEventListener("load", async () => {
                                              setFile(reader.result)
                                              setFileError(false);

                                              setFileWM(reader.result)
                                              setFileWMError(false);

                                            });
                                            reader.readAsDataURL(compressedFile)
                                          }}
                                            multiple={false} accept={[".png", ".gif", ".jpg"]}>
                                            {({ getRootProps, getInputProps }) => (
                                              <Link >
                                                <section className={"container"}>
                                                  <div {...getRootProps({ className: fileError ? 'dropzone2Error' : 'dropzone2' })}>
                                                    <input {...getInputProps()} />
                                                    <p>Drag 'n' drop your ticket art here, or click to select ticket art file</p>
                                                  </div>
                                                </section>
                                              </Link>
                                            )}
                                          </Dropzone>
                                        </GridItem>
                                        <Dialog
                                          maxWidth="sm"
                                          open={openCrop}
                                          onClose={handleCropClose}
                                          aria-labelledby="responsive-dialog-title"
                                          scroll={"body"}
                                        >
                                          <DialogTitle id="responsive-dialog-title">{"Edit Image"}</DialogTitle>
                                          <DialogContent dividers>
                                            <ReactCrop
                                              src={file}
                                              onImageLoaded={onLoad}
                                              crop={crop}
                                              onChange={c => setCrop(c)}
                                              onComplete={makeClientCrop}
                                            />
                                            <Button onClick={handleCropClose}>Done</Button>
                                          </DialogContent>
                                        </Dialog>

                                        <GridItem xs={12} sm={12} md={4} lg={3}>
                                          <ColorPicker
                                            style={{ backgroundColor: backgroundColor }}
                                            label="Background Color"
                                            name='Background Color'
                                            value={backgroundColor}
                                            onChange={color => setBackgroundColor(color)}

                                          /></GridItem>
                                        <GridItem xs={12} sm={12} md={4} lg={3}>

                                          <ColorPicker
                                            style={{ backgroundColor: textColor }}
                                            label="Font Color"
                                            name='Font Color'
                                            value={textColor}
                                            onChange={color => setTextColor(color)}

                                          /></GridItem>
                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <Grid container justify="space-around">
                                              <KeyboardDateTimePicker
                                                margin="normal"
                                                id="time-picker"
                                                label="Time picker"
                                                value={eventDate}
                                                onChange={handleDateChange}
                                                KeyboardButtonProps={{
                                                  'aria-label': 'change time',
                                                }}
                                              />
                                            </Grid>
                                          </MuiPickersUtilsProvider>
                                        </GridItem>
                                        {tokenType === "SFT" &&
                                          <GridItem xs={12} sm={12} md={6} lg={6}>
                                            <CustomInput
                                              error={assetCountError}
                                              labelText="Ticket Count *"
                                              id="assetCount"
                                              formControlProps={{
                                                fullWidth: true
                                              }}
                                              inputProps={{
                                                type: "number",
                                                // startAdornment: (
                                                //   <InputAdornment position="start">
                                                //     <AttachMoneyIcon className={classes2.inputIconsColor} />
                                                //   </InputAdornment>
                                                // ), required: true,
                                                onChange: function (e) {
                                                  setAssetCount(e.target.value)
                                                  setAssetCountError(!e.target.value || e.target.value < 0 ? true : false)
                                                }, value: assetCount,
                                              }}
                                            />
                                          </GridItem>

                                        }

                                        <GridItem xs={12} sm={12} md={6} lg={6}>
                                          <CustomInput
                                            error={priceError}
                                            labelText="Price *"
                                            id="tokenName"
                                            formControlProps={{
                                              fullWidth: true
                                            }}
                                            inputProps={{
                                              type: "number",
                                              startAdornment: (
                                                <InputAdornment position="start">
                                                  <AttachMoneyIcon className={classes2.inputIconsColor} />
                                                </InputAdornment>
                                              ), required: true,
                                              onChange: function (e) {
                                                setPrice(e.target.value)
                                                setPriceError(!e.target.value || e.target.value < 0 ? true : false)
                                              }, value: price, disabled: !tradable
                                            }}
                                          />
                                        </GridItem>

                                      </GridContainer>
                                    </GridItem>
                                    <GridItem xs={6} sm={4} md={4} lg={3}>
                                      <GridContainer justify="center">
                                        <GridItem xs={12} sm={12} md={12} lg={12} >
                                          <div type="ticket" class="widget --flex-column" >
                                            <div class="top --flex-column" style={{ backgroundColor: backgroundColor, color: textColor }}>
                                              <div class="bandname -bold">{eventName != "" ? eventName : "Event Name"}</div>
                                              <div class="tourname">{edition != "" ? edition : "Edition or Series"}</div>
                                              <Link onClick={handleCropOpen}>
                                                <div class="container">
                                                  <CardMedia style={{ height: 140 }} image={fileWM} />
                                                  <div class="overlay">
                                                    <div class="text">Resize<CropFreeIcon /></div>
                                                  </div>
                                                </div>
                                              </Link>
                                              <GridContainer  >
                                                <GridItem xs={8} sm={8} md={8} lg={8}>
                                                  <div class="date">{eventDate ? eventDate.toDateString() : "Date"}</div>
                                                  <div class="date">{eventDate ? eventDate.toLocaleTimeString() : "Time"}</div>

                                                  <div class="location -bold">{location != "" ? location : "Location"}</div>
                                                </GridItem>
                                                <GridItem xs={4} sm={4} md={4} lg={4}>
                                                  <div class="label">Price</div>
                                                  <div class="cost -bold">${price}</div>
                                                </GridItem>
                                                {/* <GridItem xs={12} sm={12} md={12} lg={12}>
                                            <div class="date">{eventDate ? eventDate.toLocaleTimeString() : "Time"}</div>
                                            </GridItem> */}

                                              </GridContainer>
                                            </div>
                                            <div class="rip" style={{ backgroundColor: backgroundColor, color: textColor }}></div>
                                            <div class="bottom --flex-row-j!sb" style={{ backgroundColor: backgroundColor, color: textColor }}>
                                              {BuildNiftronId(tokenType, tradable, tokenName, file)}
                                            </div>
                                          </div >


                                        </GridItem></GridContainer>

                                    </GridItem>

                                  </GridContainer>

                                  {loading && <LinearProgress />}
                                </CardBody>
                              }
                              <CardFooter>
                                <Button
                                  // disabled={activeStep === 0}
                                  onClick={handleBack}
                                  className={classes.backButton}
                                > Back</Button>
                                <Button variant="contained" color="info" onClick={SpecificTicket} type={"submit"}
                                  disabled={false}>
                                  {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                </Button>
                              </CardFooter>
                            </form>
                          </Card>
                        </GridItem>
                      </GridContainer>
                      }

                    </Typography>
                  </div>
                )}
            </div>
          </div>

        </GridItem>
        {/* <GridItem xs={12} sm={12} md={3}>
          <Card profile>
            <CardAvatar profile>
              <a href="#pablo" onClick={e => e.preventDefault()}>
                <img src={avatar} alt="..." />
              </a>
            </CardAvatar>
            <CardBody profile>
              <h6 className={classes.cardCategory}>CEO / CO-FOUNDER</h6>
              <h4 className={classes.cardTitle}>Alec Thompson</h4>
              <p className={classes.description}>
                Don{"'"}t be scared of the truth because we need to restart the
                human foundation in truth And I love you like Kanye loves Kanye
                I love Rick Owens’ bed design but the back is...
              </p>
              <Button color="info" round>
                Follow
              </Button>
            </CardBody>
          </Card>
        </GridItem> */}
      </GridContainer>
    </div >
  );
}
