/* eslint-disable */
import axios from "axios";
import {
  niftronUserLambda,
  //  StellarUrl,
  StellarNetwork, NiftronIssuerPublicKey, NiftronDistributorPublicKey
} from "variables/constants";
import { AES, enc } from "crypto-js";
import sha256 from "sha256";
import {
  Server, Asset, TransactionBuilder, Keypair, Transaction,
  Networks, BASE_FEE, Operation, Memo
} from "stellar-sdk";
import store from "redux/reducers"
import jwt from "jsonwebtoken";
import { buildRegisterXDR, signXDR,signXDRTestNet } from "services/XDRBuilder"
import { addLoadingMessage } from "redux/actions"
import {
  ADD_STELLAR_URL, ADD_LOADING_MESSAGE
} from '../redux/constants/ActionTypes'

let StellarUrl = store.getState().StellarUrl
let StellarUrlTest = store.getState().StellarUrlTest

store.subscribe(() => {
  StellarUrl = store.getState().StellarUrl
  StellarUrlTest = store.getState().StellarUrlTest
})
/**
 * @author Zeemzo azeemashraf@outlook.com
 */
export function hashEmail(email) {
  return sha256(email);
}
export function decryptSecret(secret, signer) {
  try {
    const decrypted = AES.decrypt(secret, signer);
    const plaintext = decrypted.toString(enc.Utf8);
    return plaintext;
  } catch (error) {
    //console.log(error)
    return null;
  }
}
export function encyrptSecret(secret, signer) {
  try {
    const ciphertext = AES.encrypt(secret, signer);
    return ciphertext.toString();
  } catch (error) {
    return null;
  }
}

export async function getNiftronCreditBalance(publicKey, test) {
  try {
      let response = null
      let server = new Server(test ? StellarUrlTest : StellarUrl);
      let sourceAccount;
      try {
          sourceAccount = await server.loadAccount(publicKey);
      } catch (err) {
          try {
              server = new Server(StellarUrlTest);
              sourceAccount = await server.loadAccount(publicKey);
          } catch (err2) {
              console.log(err2)
              return null
          }
      }
      sourceAccount.balances.forEach(function (balance) {
          // @ts-ignore
          // console.log('Asset_code:', balance.asset_code, ', Balance:', balance.balance);
          let bal = parseFloat(balance.balance)

          // let lim = parseFloat(balance.limit)
          if (balance.asset_type != "native" && balance.asset_issuer == NiftronIssuerPublicKey && balance.asset_code == "NIFTRON") {
              response = {
                  assetCode: balance.asset_code, balance: bal,
                  limit: balance.limit, issuer: balance.asset_issuer
              };

          }
      });
      return response
  } catch (err) {
      console.log(err)
      return null;
  }
}

export async function getXLMBalance(publicKey, test) {
  try {
      let response = null
      let server = new Server(test ? StellarUrlTest : StellarUrl);
      let sourceAccount;
      try {
          sourceAccount = await server.loadAccount(publicKey);
      } catch (err) {
          try {
              server = new Server(StellarUrlTest);
              sourceAccount = await server.loadAccount(publicKey);
          } catch (err2) {
              console.log(err2)
              return null
          }
      }
      sourceAccount.balances.forEach(function (balance) {
          // @ts-ignore
          // console.log('Asset_code:', balance.asset_code, ', Balance:', balance.balance);
          let bal = parseFloat(balance.balance)

          // let lim = parseFloat(balance.limit)
          if (balance.asset_type == "native") {
              response = {
                  assetCode: balance.asset_code, balance: bal,
                  limit: balance.limit, issuer: balance.asset_issuer
              };

          }
      });
      return response
  } catch (err) {
      console.log(err)
      return null;
  }
}

export async function login(key, password) {
  try {

    store.dispatch({ type: ADD_LOADING_MESSAGE, text: "verifying credentials ..." })

    let pash = hashEmail(password);
    ////console.log(pash)
    let postBody = {
      key: key,
      password: pash
    };
    const res = await axios.post(niftronUserLambda + "/users/login", postBody, {
      headers: {
        // 'Authorization': "bearer " + token,
        "Content-Type": "application/json"
      }
    });

    if (res != null) {
      if (res.status === 200) {
        // store.dispatch({
        //   type: "ADD_USER",
        //   text: "validating credentials"
        // });
        // localStorage.setItem("keypair", JSON.stringify(keypair))
        localStorage.setItem("token", res.data.data.token);
        return res.status;
      } else {
        return res.status;
      }
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
}
export async function loginWithSecret(secretKey) {
  try {
    store.dispatch({ type: ADD_LOADING_MESSAGE, text: "building blockchain transaction for login ..." })


    let keypair = Keypair.fromSecret(secretKey)
    const { xdr, reactivate } = await buildLoginXDR(keypair);
    if (xdr === null) {
      return null
    }

    //console.log(xdr)
    let postBody = {
      xdr: xdr,
      reactivate
    };
    store.dispatch({ type: ADD_LOADING_MESSAGE, text: "verifying credentials" })

    const res = await axios.post(
      niftronUserLambda + "/users/xdrLogin",
      postBody,
      {
        headers: {
          // 'Authorization': "bearer " + token,
          "Content-Type": "application/json"
        }
      }
    );

    if (res != null) {
      if (res.status === 200) {
        // localStorage.setItem("keypair", JSON.stringify(keypair))
        localStorage.setItem("token", res.data.data.token);
        return res.status;
      } else {
        return res.status;
      }
    } else {
      return null;
    }
  } catch (err) {
    //console.log(err)
    return null;
  }
}
export async function buildLoginXDR(keypair) {
  try {
    let server = new Server(StellarUrlTest);
    let sourceAccount;
    let networkPassphrase;
    try {
      sourceAccount = await server.loadAccount(keypair.publicKey());
      networkPassphrase = Networks.TESTNET
    } catch (err2) {

      //if testnet doesn't have the account redo
      // //fund main account
      await fundAccountInTestnet(keypair.publicKey())

      let { xdrs, secondaryPublicKey } = await buildRegisterXDR(keypair.publicKey(),true)

      await Promise.all(
        xdrs.map(async (item, index, array) => {
          xdrs[index].xdr = await signXDRTestNet(item.xdr, keypair.secret());
        })
      );

      console.log(xdrs[0].xdr)
      return { xdr: xdrs[0].xdr, reactivate: true }
      // }
    }

    let transaction = new TransactionBuilder(sourceAccount, {
      fee: "200",
      networkPassphrase: networkPassphrase
    })
      .addOperation(Operation.manageData({ name: 'login', value: new Date().toUTCString(), }))
      .setTimeout(0)
      .build();
    transaction.sign(keypair);
    // transaction.hash().toString('hex')
    return { xdr: transaction.toEnvelope().toXDR('base64'), reactivate: false }
  } catch (e) {
    //console.log(e)
    return null
  }
}
export async function checkEmail(email) {
  try {
    const res = await axios.get(`${niftronUserLambda}/users/${email}/checkEmail`, {
      headers: {
        // 'Authorization': "bearer " + token,
        "Content-Type": "application/json"
      }
    });

    if (res != null) {
      return res.data.data;
    }
    return null;

  } catch (err) {
    return null;
  }
}
export async function checkAlias(alias) {
  try {
    const res = await axios.get(`${niftronUserLambda}/users/${alias}/checkAlias`, {
      headers: {
        // 'Authorization': "bearer " + token,
        "Content-Type": "application/json"
      }
    });

    if (res != null) {
      return res.data.data;
    }
    return null;

  } catch (err) {
    return null;
  }
}
export async function register(
  type, alias, email, password, 
  recoveryQuestion, securityAnswer, keypair, authType) {
  try {
    store.dispatch({type:ADD_LOADING_MESSAGE,text:"creating blockchain keypair ..."})
    // let keypair = Keypair.random();

    let publicKey = keypair.publicKey();

    let pash = "";
    let encryptedSecret = "";
    let encryptedRecoverySecret = "";


    if (password != "") {
      // store.dispatch({type:ADD_LOADING_MESSAGE,text:"encrypting secret key"})

      pash = hashEmail(password);
      encryptedSecret = encyrptSecret(keypair.secret(), pash);
    }

    if (securityAnswer != "") {
      // store.dispatch({type:ADD_LOADING_MESSAGE,text:"encrypting recovery answer"})

      encryptedRecoverySecret = encyrptSecret(keypair.secret(), hashEmail(securityAnswer.toLowerCase()));
    }

    // store.dispatch({type:ADD_LOADING_MESSAGE,text:"temporarily activating blockchain account"})

    // //fund main account
    await fundAccountInTestnet(keypair.publicKey())
    // //add niftron signer to first account
    // await AddNiftronSigner(keypair)

    store.dispatch({type:ADD_LOADING_MESSAGE,text:"building blockchain transaction for account registration ..."})

    let { xdrs, secondaryPublicKey,secondarySecretKey } = await buildRegisterXDR(keypair.publicKey(),false)

    store.dispatch({type:ADD_LOADING_MESSAGE,text:"signing blockchain transactions ..."})

    await Promise.all(
      xdrs.map(async (item, index, array) => {
        xdrs[index].xdr = await signXDR(item.xdr, keypair.secret());
      })
    );

    let accounts = []
    accounts.push({ publicKey: keypair.publicKey(), accountType: "0" })
    // if (type === "0") {
    accounts.push({ publicKey: secondaryPublicKey, accountType: "1" })
    // //fund second account
    // await fundAccount(secondKeypair.publicKey())
    // //add niftron signer and main signer to second account
    // await AddMainSigner(secondKeypair, keypair)
    // //add niftron signer to second account
    // await AddNiftronSignerForSecond(secondKeypair, keypair)
    // // }

    let postBody = {
      type: type,
      alias: alias.toLowerCase(),
      email: email != "" ? email.toLowerCase() : "",
      publicKey: publicKey,
      encryptedSecret: encryptedSecret,
      encryptedRecoverySecret: encryptedRecoverySecret,
      recoveryQuestion: recoveryQuestion,
      authType: authType,
      accounts: accounts,
      secondarySecretKey,
      xdrs
    };
    store.dispatch({type:ADD_LOADING_MESSAGE,text:"submitting registration request to niftron ..."})

    const res = await axios.post(niftronUserLambda + "/users/register", postBody, {
      headers: {
        // 'Authorization': "bearer " + token,
        "Content-Type": "application/json"
      }
    });

    if (res !== null) {
      if (res.status === 200) {
        //////console.log("Success:" + res.data.data.token)
        store.dispatch({type:ADD_LOADING_MESSAGE,text:"registration completed ..."})

        localStorage.setItem("token", res.data.data.token);

        return res.status;
      } else {
        // //////console.log("already: " + res.status)
        return res.status;
      }
    } else {
      return null;
    }
  } catch (err) {
    // //////console.log(err)
    return null;
  }
}
export async function addKyc(kycModel) {
  try {
    let token;
    if (localStorage.getItem("token") != null) {
      token = localStorage.getItem("token");
    }
    let postBody = kycModel
    const res = await axios.post(niftronUserLambda + "/users/kyc", postBody, {
      headers: {
        'Authorization': "bearer " + token,
        "Content-Type": "application/json"
      }
    });

    if (res === null) {
      return null
    }
    return res.status;
  } catch (err) {
    return null;
  }
}
export async function GetAccount(key) {
  try {
    // let token;
    // if (localStorage.getItem("token") != null) {
    //   token = localStorage.getItem("token");
    // }
    const res = await axios.get(niftronUserLambda + "/users/" + key, {
      headers: {
        // 'Authorization': "bearer " + token,
        "Content-Type": "application/json"
      }
    });

    if (res != null) {
      if (res.status === 200) {
        return res.data;
      } else {
        return res.data;
      }
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
}
export async function requestPasswordReset(key) {
  try {
    // let token;
    // if (localStorage.getItem("token") != null) {
    //   token = localStorage.getItem("token");
    // }
    store.dispatch({ type: ADD_LOADING_MESSAGE, text: "requesting available reset options ..." })

    const res = await axios.get(`${niftronUserLambda}/users/${key}/requestPasswordReset`, {
      headers: {
        // 'Authorization': "bearer " + token,
        "Content-Type": "application/json"
      }
    });

    if (res != null) {
      if (res.status === 200) {
        //console.log(res)
        return res.data;
      } else {
        return res.data;
      }
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
}
export async function passwordReset(secretKey, encryptedSecret, otp) {
  try {
    store.dispatch({ type: ADD_LOADING_MESSAGE, text: "testing user credibility ..." })

    let keypair;
    try {
      keypair = Keypair.fromSecret(secretKey)
    } catch (err) {
      console.lof(err)
      return null
    }

    const { xdr } = await buildLoginXDR(keypair);
    if (xdr === null) {
      return null
    }

    //console.log(xdr)
    let postBody = {
      xdr,
      encryptedSecret
    };

    if (otp != "") {
      postBody.otp = otp
    }

    store.dispatch({ type: ADD_LOADING_MESSAGE, text: "resetting password ..." })

    const res = await axios.post(
      `${niftronUserLambda}/users/passwordReset`,
      postBody,
      {
        headers: {
          // 'Authorization': "bearer " + token,
          "Content-Type": "application/json"
        }
      }
    );

    if (res != null) {
      if (res.status === 200) {
        // localStorage.setItem("keypair", JSON.stringify(keypair))
        localStorage.setItem("token", res.data.data.token);

        return res.status;
      } else {
        return res.status;
      }
    } else {
      return null;
    }
  } catch (err) {
    //console.log(err)
    return null;
  }
}
export async function SearchOrGetAccounts(Key) {
  try {
    let token;
    if (localStorage.getItem("token") != null) {
      token = localStorage.getItem("token");
    }

    const query = Key === "" ? "?key=" + Key : "";
    const res = await axios.get(niftronUserLambda + "/users" + query, {
      headers: {
        'Authorization': "bearer " + token,
        "Content-Type": "application/json"
      }
    });

    if (res != null) {
      if (res.status === 200) {
        return res.data.data;
      } else {
        return res.data.data;
      }
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
}
export async function getWalletBalance(publicKey) {

  try {

    let assets = [];

    var server = new Server(StellarUrl);
    // the JS SDK uses promises for most actions, such as retrieving an account
    const account = await server.loadAccount(publicKey);
    if (account === null) {
      return null
    }
    account.balances.forEach(function (balance) {
      // @ts-ignore
      // ////////console.log('Asset_code:', balance.asset_code, ', Balance:', balance.balance);
      let bal = parseFloat(balance.balance)
      let lim = parseFloat(balance.limit)

      // @ts-ignore
      assets.push({ 'assetCode': balance.asset_code, 'balance': bal.toFixed(0), 'limit': lim.toFixed(0) });
    });
    // assets.pop();
    ////////console.log(assets)
    return assets;
  } catch (err) {
    return null;
  }
}
export function getUserSession() {
  if (localStorage.getItem("token") !== null) {
    // jwt.decode(localStorage.getItem("token"))
    const decodedToken = jwt.decode(localStorage.getItem("token"));
    if (decodedToken === null) {
      return null;
    } else {
      // ////////console.log(decodedToken)
      return decodedToken;
    }
  }
}
export async function TransferFund(DestinationPublicKey, Amount, keypair, sender, assetName) {
  try {
    var server = new Server(StellarUrl);
    const sourceAccount = await server.loadAccount(keypair.publicKey());
    if (sourceAccount === null) {
      return null
    }
    let transaction = new TransactionBuilder(sourceAccount, {
      fee: BASE_FEE,
      networkPassphrase: Networks.TESTNET
    })
      .addOperation(Operation.payment({
        destination: NiftronDistributorPublicKey,
        asset: Asset.native(),
        amount: Amount
      }))
      .addOperation(Operation.payment({
        destination: keypair.publicKey(),
        asset: new Asset(assetName, NiftronIssuerPublicKey),
        amount: Amount,
        source: NiftronDistributorPublicKey
      }))
      .addOperation(Operation.payment({
        destination: DestinationPublicKey,
        asset: new Asset(assetName, NiftronIssuerPublicKey),
        amount: Amount
      }))
      .addMemo(Memo.text(sender))
      .build();
    // Sign the transaction to prove you are actually the person sending it.
    transaction.sign(keypair);
    ////console.log(transaction.toEnvelope().toXDR('base64'))
    let token;
    if (localStorage.getItem("token") != null) {
      token = localStorage.getItem("token");
    }
    const res = await axios.post(
      niftronUserLambda + "/transactions/fund",
      { xdr: transaction.toEnvelope().toXDR('base64') },
      {
        headers: {
          Authorization: "bearer " + token,
          "Content-Type": "application/json",
        }
      }
    );
    ////console.log(res)
    return res.status
  } catch (e) {
    ////console.log(e)
    return null
  }
}
export async function getIp() {
  try {
    const res = await axios.get("https://www.cloudflare.com/cdn-cgi/trace");
    if (res === null) {
      return null;
    }

    const startingIndex = res.data.indexOf("ip=");
    const EndingIndex = res.data.indexOf("ts=");
    const ip = res.data.substring(startingIndex + 3, EndingIndex - 1);

    localStorage.setItem("ip", ip);
  } catch (err) {
    //////console.log(err);
    return null;
  }
}
/**
 * Fund Account In Testnet
 * @param {string} publicKey string.
 * @returns {boolean} response boolean
 */
export const fundAccountInTestnet = async (
  publicKey
) => {
  try {
    // if (StellarNetwork === "TestNet") {
    const STELLAT_FRIEND_BOT_URL = `https://friendbot.stellar.org/?addr=`;
    const stellarResponse = await axios.get(
      `${STELLAT_FRIEND_BOT_URL}${publicKey}`
    );

    if (stellarResponse !== null && stellarResponse.status !== 200) {
      return null
    }
    // //console.log("funded")
    return true;
    // }
  } catch (err) {
    return null
  }
};