
import axios from "axios";
import { getUserSession } from "./UserManagement"
import { niftronUserLambda, niftronTokenLambda, StellarUrl, NiftronAPI,StellarUrlTest, StellarNetwork, NiftronIssuerPublicKey, NiftronDistributorPublicKey } from "variables/constants";
import { AES, enc } from "crypto-js";
import sha256 from "sha256";
import { Server, Keypair, Asset, Transaction, Networks } from "stellar-sdk";
//old
export async function BuildTicketNFTXDR(tokenName, tokenType, transferable, authorizable, keypair, tradable, assetCount, dataHash) {
  try {
    let postBody = {
      tokenName,
      tokenType,
      tradable,
      transferable,
      authorizable,
      primaryPublicKey: keypair.publicKey(),
      merchantPublicKey: NiftronDistributorPublicKey,
      assetCount: assetCount,
      dataHash: dataHash
    }
    const res = await axios.post(niftronTokenLambda + "/xdrBuilder/mint/ticket", postBody, {
      headers: {
        "Content-Type": "application/json"
      }
    });
    if (res === null) {
      return null
    }
    const xdrs = res.data.data
    // const secondaryPublicKey = res.data.metaData?.secondaryPublicKey
    return {
      xdrs//, secondaryPublicKey
    };
  } catch (err) {
    //console.log(err);
    return null;
  }
}
export async function BuildArtNFTXDR(tokenName, tokenType, tradable, transferable, authorizable, keypair, assetCount, dataHash) {
  try {
    let postBody = {
      tokenName,
      tokenType,
      tradable,
      transferable,
      authorizable,
      primaryPublicKey: keypair.publicKey(),
      merchantPublicKey: NiftronDistributorPublicKey,
      assetCount: assetCount,
      dataHash: dataHash
    }
    const res = await axios.post(niftronTokenLambda + "/xdrBuilder/mint/digitalart", postBody, {
      headers: {
        "Content-Type": "application/json"
      }
    });
    if (res === null) {
      return null
    }
    const xdrs = res.data.data
    const niftronId = res.data.metaData?.niftronId
    return { xdrs, niftronId };
  } catch (err) {
    //console.log(err);
    return null;
  }
}
export async function BuildTransferNFTXDR(
  sender,
  receiver,
  assetIssuer,
  assetCode,
  assetCount,
  approvers,
  minTime,
  maxTime) {
  try {
    let postBody = {
      sender,
      receiver,
      merchant: NiftronDistributorPublicKey,
      assetIssuer,
      assetCode,
      assetCount,
      approvers,
      minTime,
      maxTime
    }
    const res = await axios.post(niftronTokenLambda + "/xdrBuilder/transfer/certificate", postBody, {
      headers: {
        "Content-Type": "application/json"
      }
    });
    if (res === null) {
      return null
    }
    const xdrs = res.data.data
    // const secondaryPublicKey = res.data.metaData?.secondaryPublicKey
    return {
      xdrs//, secondaryPublicKey
    };
  } catch (err) {
    //console.log(err);
    return null;
  }
}
export async function BuildTransferCertificateNFTXDR(
  sender,
  receiver,
  assetIssuer,
  assetCode,
  assetCount,
  approvers,
  minTime,
  maxTime) {
  try {
    let postBody = {
      sender,
      receiver,
      merchant: NiftronDistributorPublicKey,
      assetIssuer,
      assetCode,
      assetCount,
      approvers,
      minTime,
      maxTime
    }
    const res = await axios.post(niftronTokenLambda + "/xdrBuilder/transfer/certificate", postBody, {
      headers: {
        "Content-Type": "application/json"
      }
    });
    if (res === null) {
      return null
    }
    const xdrs = res.data.data
    // const secondaryPublicKey = res.data.metaData?.secondaryPublicKey
    return {
      xdrs//, secondaryPublicKey
    };
  } catch (err) {
    //console.log(err);
    return null;
  }
}
export async function BuildClaimBadgeNFTXDR(
  sender,
  receiver,
  assetIssuer,
  assetCode,
  assetCount,
  approvers,
  minTime,
  maxTime) {
  try {
    let postBody = {
      sender,
      receiver,
      merchant: NiftronDistributorPublicKey,
      assetIssuer,
      assetCode,
      assetCount,
      approvers,
      minTime,
      maxTime
    }
    const res = await axios.post(niftronTokenLambda + "/xdrBuilder/claim/badge", postBody, {
      headers: {
        "Content-Type": "application/json"
      }
    });
    if (res === null) {
      return null
    }
    // const xdrs = res.data.data
    // const secondaryPublicKey = res.data.metaData?.secondaryPublicKey
    return res.data
  } catch (err) {
    //console.log(err);
    return null;
  }
}
export async function BuildCertificateNFTXDR(
  tokenName, tokenType, tradable, transferable,
  authorizable, keypair, assetCount, dataHash) {
  try {
    let postBody = {
      tokenName,
      tokenType,
      tradable,
      transferable,
      authorizable,
      primaryPublicKey: keypair.publicKey(),
      merchantPublicKey: NiftronDistributorPublicKey,
      assetCount: assetCount,
      dataHash: dataHash
    }
    const res = await axios.post(niftronTokenLambda + "/xdrBuilder/mint/certificate", postBody, {
      headers: {
        "Content-Type": "application/json"
      }
    });
    if (res === null) {
      return null
    }
    const xdrs = res.data.data
    const niftronId = res.data.metaData?.niftronId
    return { xdrs, niftronId };
  } catch (err) {
    //console.log(err);
    return null;
  }
}
export async function buildRegisterXDR(primaryPublicKey, reactivate) {
  try {
    let postBody = { primaryPublicKey, merchantPublicKey: NiftronDistributorPublicKey, reactivate }
    const res = await axios.post(niftronUserLambda + "/xdrBuilder/register", postBody, {
      headers: {
        "Content-Type": "application/json"
      }
    });
    if (res === null) {
      return null
    }
    const xdrs = res.data.data
    const secondaryPublicKey = res.data.metaData?.secondaryPublicKey
    const secondarySecretKey = res.data.metaData?.secondarySecretKey

    return {
      xdrs, secondaryPublicKey, secondarySecretKey
    };
  } catch (err) {
    return null;
  }
}
export async function signXDR(xdr, secretKey, reactivate) {
  try {
    let parsedTx;
    let publicKey;
    let server = new Server(StellarUrl);
    let keypair = Keypair.fromSecret(secretKey);

    let sourceAccount;
    try {
      parsedTx = new Transaction(xdr, Networks.PUBLIC);
      publicKey = parsedTx.source;
      sourceAccount = await server.loadAccount(keypair.publicKey());
    } catch (err) {
      try {
        server = new Server(StellarUrlTest);
        parsedTx = new Transaction(xdr, Networks.TESTNET);
        publicKey = parsedTx.source;
        sourceAccount = await server.loadAccount(keypair.publicKey());
      } catch (err2) {
        return null
      }
    }
    parsedTx.sign(keypair);
    let x = parsedTx.toEnvelope().toXDR().toString("base64");
    return x;
  } catch (err) {
    //console.log(err)
    return null;
  }
}
export async function signXDRTestNet(xdr, secretKey) {
  try {
    let keypair = Keypair.fromSecret(secretKey);

    let parsedTx = new Transaction(xdr, Networks.TESTNET);;
    let publicKey = parsedTx.source;;
    let server = new Server(StellarUrlTest);
    let sourceAccount;
    try {
      sourceAccount = await server.loadAccount(keypair.publicKey());
    } catch (err2) {
      return null
    }
    parsedTx.sign(keypair);
    let x = parsedTx.toEnvelope().toXDR().toString("base64");
    return x;
  } catch (err) {
    //console.log(err)
    return null;
  }
}
export async function BuildProofXDR(signer, txnHash, type) {
  try {
    let postBody = {
      signer,
      merchant: NiftronDistributorPublicKey,
      txnHash,
      type
    }
    const res = await axios.post(niftronTokenLambda + "/xdrBuilder/proof", postBody, {
      headers: {
        "Content-Type": "application/json"
      }
    });
    if (res === null) {
      return null
    }
    const xdrs = res.data.data
    // const secondaryPublicKey = res.data.metaData?.secondaryPublicKey
    return {
      xdrs//, secondaryPublicKey
    };
  } catch (err) {
    //console.log(err);
    return null;
  }
}
export async function BuildTransferBadgeNFTXDR(
  sender,
  receiver,
  assetIssuer,
  assetCode,
  assetCount,
  approvers,
  minTime,
  maxTime) {
  try {
    let postBody = {
      sender,
      receiver,
      merchant: NiftronDistributorPublicKey,
      assetIssuer,
      assetCode,
      assetCount,
      approvers,
      minTime,
      maxTime
    }
    const res = await axios.post(niftronTokenLambda + "/xdrBuilder/transfer/badge", postBody, {
      headers: {
        "Content-Type": "application/json"
      }
    });
    if (res === null) {
      throw new Error("Insufficient Fund")
    }
    const xdrs = res.data.data
    // const secondaryPublicKey = res.data.metaData?.secondaryPublicKey
    return {
      xdrs//, secondaryPublicKey
    };
  } catch (err) {
    console.log(err);
    throw new Error("Insufficient Fund")
  }
}
export async function BuildAccess(
  sender,
  receiver,
  accessType,
  accessCount,
  dataHash) {
  try {
    let postBody = {
      sender,
      receiver,
      merchantPublicKey: NiftronDistributorPublicKey,
      accessType,
      accessCount,
      dataHash
    }
    const res = await axios.post(niftronTokenLambda + "/xdrBuilder/access", postBody, {
      headers: {
        "Content-Type": "application/json"
      }
    });
    if (res === null) {
      throw new Error("Insufficient Fund")
    }
    const xdrs = res.data.data
    const accessId = res.data.metaData?.accessId
    return {
      xdrs, accessId
    };
  } catch (err) {
    console.log(err);
    throw new Error("Insufficient Fund")
  }
}
export async function BuildStellarBurn(
  sender,
  assetIssuer,
  assetCode,
  assetCount) {
  try {
    let postBody = {
      sender,
      merchant: NiftronDistributorPublicKey,
      assetIssuer,
      assetCode,
      assetCount
    }
    const res = await axios.post( NiftronAPI+ "/xdrs/burn/STELLAR", postBody, {
      headers: {
        "Content-Type": "application/json"
      }
    });
    if (res === null) {
      throw new Error("Insufficient Fund")
    }
    const xdrs = res.data.data
    // const secondaryPublicKey = res.data.metaData?.secondaryPublicKey
    return {
      xdrs//, secondaryPublicKey
    };
  } catch (err) {
    console.log(err);
    throw new Error("Insufficient Fund")
  }
}
export async function BuildPayForCross(
  sender,
  assetIssuer,
  assetCode,
  assetCount) {
  try {
    let postBody = {
      sender,
      merchant: NiftronDistributorPublicKey,
      assetIssuer,
      assetCode,
      assetCount
    }
    const res = await axios.post( NiftronAPI+ "/xdrs/burn/STELLAR", postBody, {
      headers: {
        "Content-Type": "application/json"
      }
    });
    if (res === null) {
      throw new Error("Insufficient Fund")
    }
    const xdrs = res.data.data
    // const secondaryPublicKey = res.data.metaData?.secondaryPublicKey
    return {
      xdrs//, secondaryPublicKey
    };
  } catch (err) {
    console.log(err);
    throw new Error("Insufficient Fund")
  }
}
