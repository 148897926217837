/* eslint-disable */
import React from 'react'
// import { withRouter } from "react-router-dom";
// import jwt from "jsonwebtoken";
import Niftron from 'baas/config'

// const withAuthorization = authCondition => Component => {
//   class WithAuthorization extends React.Component {
//     componentDidMount() {
//       if (localStorage.getItem("token") != null) {
//         // jwt.decode(localStorage.getItem("token"))
//         jwt.verify(
//           localStorage.getItem("token"),
//           "ijk3dp4n",
//           (err, decodedToken) => {
//             if (err || !decodedToken) {
//               localStorage.removeItem("token");
//               ////console.log(this.props.location);
//               this.props.history.push(
//                 `/auth?from=${btoa(this.props.location.pathname)}`
//               );
//             } else {
//               if (!authCondition(decodedToken)) {
//                 ////console.log(this.props.location);
//                 this.props.history.push(
//                   `/auth?from=${btoa(this.props.location.pathname)}`
//                 );
//               }
//             }
//           }
//         );
//       } else {
//         ////console.log(this.props.location);

//         this.props.history.push(
//           `/auth?from=${btoa(this.props.location.pathname)}`
//         );
//       }
//     }

//     render() {
//       return <Component {...this.props} />;
//     }
//   }

//   return withRouter(WithAuthorization);
// };

const withAuthorization = authCondition => Component => {
  const WithAuthorization = props => {
    const [render, setRender] = React.useState(null)
    React.useEffect(() => {
      const checkAuth = () => {
        Niftron.user.onAuthStateChanged(
          authUser => {
            // console.log(authUser)
            if (!authCondition(authUser)) {
              localStorage.removeItem('secretKey')
              Niftron.user.authRedirect(
                {
                  redirectUrl: window.location.href,
                  warning: 'Login with a User Account',
                  userType: '1'
                },
                true
              )
              // setRender(<Navigate to="/auth" />)
            } else {
              setRender(<Component {...props} />)
            }
          },
          err => {
            if (err) {
              // console.log(err)
              localStorage.removeItem('secretKey')
              Niftron.user.authRedirect(
                {
                  redirectUrl: window.location.href,
                  warning: 'Login with a User Account',
                  userType: '1'
                },
                true
              )
              // setRender(<Navigate to="/auth" />)
            }
          }
        )
      }
      checkAuth()
    }, [])
    return render
  }
  return WithAuthorization
}
export default withAuthorization
