
import React, { useState, useEffect } from "react";
// react plugin for creating charts
// import ChartistGraph from "react-chartist";
// @material-ui/core
// import Icon from "@material-ui/core/Icon";
// @material-ui/icons
// import Store from "@material-ui/icons/Store";
// import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
// import LocalOffer from "@material-ui/icons/LocalOffer";
// import Update from "@material-ui/icons/Update";
// import ArrowUpward from "@material-ui/icons/ArrowUpward";
// import AccessTime from "@material-ui/icons/AccessTime";
// import Accessibility from "@material-ui/icons/Accessibility";
// import BugReport from "@material-ui/icons/BugReport";
// import Code from "@material-ui/icons/Code";
// import Cloud from "@material-ui/icons/Cloud";
import { CardMedia } from '@material-ui/core';

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
// import Table from "components/Table/Table.js";
// import Tasks from "components/Tasks/Tasks.js";
// import CustomTabs from "components/CustomTabs/CustomTabs.js";
// import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
// import CardHeader from "components/Card/CardHeader.js";
import CardToken from "components/Card/CardToken.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

// import Button from "components/CustomButtons/Button.js";

import { format } from 'timeago.js';

// import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import TransferCertificate from "views/Token/Transfer/TransferCertificate"

import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import axios from "axios";
const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        // width: 500,
    },
}));
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

export default function Certificate(props) {
    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const [ipfsData, setIpfsData] = useState("")
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };


    useEffect(() => {
        async function fetchIpfs() {
            try {
                const res = await axios.get(`https://ipfs.infura.io/ipfs/${props.item.ipfsHash}`);
                if (res) {
                    // //console.log(res.data)
                    if (Object.keys(res.data).length === 0) {
                        setIpfsData("NA")
                    } else {
                        setIpfsData(JSON.stringify(res.data))
                    }
                    // setIpfsData(res.data)
                }
            } catch (er) {
                //console.log(er)
            }
        }
        fetchIpfs()
    }, []);

    return (
        <Card>

            <GridContainer >
                <GridItem xs={12} sm={6} md={4} lg={4} >
                    <Card>
                        <CardToken color="grey">
                            <CardMedia style={{ height: 345, maxHeight: 345 }} image={props.item.previewUrl} />
                        </CardToken>
                        <CardFooter stats>
                            <div className={classes.stats}>
                                <DateRange />
                                {format(props.item.createdAt)}
                            </div>
                        </CardFooter>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={8} lg={8} >
                    <div className={classes.root}>
                        <AppBar position="static" color="default">
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="scrollable"
                                scrollButtons="auto"

                                aria-label="full width tabs example"
                            >
                                <Tab label="Details" />
                                {/* <Tab label="Issue Certificate" /> */}
                            </Tabs>
                        </AppBar>
                        <SwipeableViews
                            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                            index={value}
                            onChangeIndex={handleChangeIndex} >
                            <TabPanel value={value} index={0} dir={theme.direction}>
                                <Card>
                                    <CardBody style={{ height: 345, maxHeight: 345 }}>
                                        <GridContainer >
                                            <GridItem xs={12} sm={12} md={6} lg={6} >
                                                Name: {props.item.tokenName}
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={6} lg={6} >
                                                Niftron ID: {props.item.assetCode}
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={6} lg={6} >
                                                Quantity: {props.item.assetCount}
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={6} lg={6} >
                                                Type: {props.item.tokenType}
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={6} lg={6} >
                                                Category: {props.item.category}
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={6} lg={6} >
                                                Data: {ipfsData}
                                            </GridItem></GridContainer>
                                    </CardBody>
                                </Card>
                            </TabPanel>
                            <TabPanel value={value} index={1} dir={theme.direction}>
                                <TransferCertificate item={props.item} /></TabPanel>
                        </SwipeableViews>
                    </div>

                </GridItem>
            </GridContainer>

        </Card>
    );
}
