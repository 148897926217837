
import React from "react";
// react plugin for creating charts
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import Art from "views/Explorer/Components/Art"
import Ticket from "views/Explorer/Components/Ticket"
import Certificate from "views/Explorer/Components/Certificate"

const useStyles = makeStyles(styles);

export default function TokenMorpher(props) {
    const classes = useStyles();
    return (
        <div>
            {props.item.category === "DIGITALART" &&
                <Art item={props.item}
                />}

            {props.item.category === "CERTIFICATE" &&
                <Certificate item={props.item}
                />}
            {props.item.category === "TICKET" &&
                <Ticket
                    item={props.item}
                    eventName={props.item.eventName}
                    edition={props.item.edition}
                    image={props.item.previewUrl}
                    eventDate={props.item.eventDate}
                    location={props.item.location}
                    price={props.item.price}
                    niftronId={props.item._id}
                    createdAt={props.item.createdAt}
                />}

        </div>




    );
}
