import React, { useEffect, useState } from 'react'
// react components for routing our app without refresh
import { withRouter } from 'react-router-dom'
import Niftron from 'baas/config'

import classNames from 'classnames'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Hidden from '@material-ui/core/Hidden'
import Poppers from '@material-ui/core/Popper'
import Divider from '@material-ui/core/Divider'
// @material-ui/icons
import Person from '@material-ui/icons/Person'
import Notifications from '@material-ui/icons/Notifications'
import Dashboard from '@material-ui/icons/Dashboard'
import Search from '@material-ui/icons/Search'
// core components
import CustomInput from 'components/CustomInput/CustomInput.js'
import Button from 'components/CustomButtons/Button.js'
import { useSnackbar } from 'notistack'
import Tooltip from '@material-ui/core/Tooltip'

import styles from 'assets/jss/material-dashboard-react/components/headerLinksStyle.js'
import { getUserSession } from 'services/UserManagement.js'
import Avatar from 'react-avatar'

const useStyles = makeStyles(styles)

function AdminNavbarLinks (props) {
  const classes = useStyles()
  const [openNotification, setOpenNotification] = React.useState(null)
  const [openProfile, setOpenProfile] = React.useState(null)
  const [user, setUser] = useState(getUserSession())
  const { enqueueSnackbar } = useSnackbar()

  const handleClickNotification = event => {
    if (openNotification && openNotification.contains(event.target)) {
      setOpenNotification(null)
    } else {
      setOpenNotification(event.currentTarget)
    }
  }
  const handleCloseNotification = () => {
    setOpenNotification(null)
  }
  const handleClickProfile = event => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null)
    } else {
      setOpenProfile(event.currentTarget)
    }
  }
  const handleCloseProfile = () => {
    setOpenProfile(null)
  }
  const copyMessage = val => {
    const selBox = document.createElement('textarea')
    selBox.style.position = 'fixed'
    selBox.style.left = '0'
    selBox.style.top = '0'
    selBox.style.opacity = '0'
    selBox.value = val
    document.body.appendChild(selBox)
    selBox.focus()
    selBox.select()
    document.execCommand('copy')
    document.body.removeChild(selBox)
  }

  const logout = e => {
    e.preventDefault()
    localStorage.removeItem('token')
    localStorage.removeItem('niftoken')
    localStorage.removeItem('secretKey')
    Niftron.user.authRedirect({
      redirectUrl: window.location.href,
      // warning: "Login with a Profile Account",
      userType: '2'
    },true)
  }

  return (
    <div>
      {/* <div className={classes.searchWrapper}>
        <CustomInput
          formControlProps={{
            className: classes.margin + " " + classes.search
          }}
          inputProps={{
            placeholder: "Search",
            inputProps: {
              "aria-label": "Search"
            }
          }}
        />
        <Button color="white" aria-label="edit" justIcon round>
          <Search />
        </Button>
      </div> */}
      {/* <Button
        color={window.innerWidth > 959 ? "transparent" : "white"}
        justIcon={window.innerWidth > 959}
        simple={!(window.innerWidth > 959)}
        aria-label="Dashboard"
        className={classes.buttonLink}
      >
        <Dashboard className={classes.icons} />
        <Hidden mdUp implementation="css">
          <p className={classes.linkText}>Dashboard</p>
        </Hidden>
      </Button> */}
      {/* <div className={classes.manager}>
        <Button
          color={window.innerWidth > 959 ? "transparent" : "white"}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-owns={openNotification ? "notification-menu-list-grow" : null}
          aria-haspopup="true"
          onClick={handleClickNotification}
          className={classes.buttonLink}
        >
          <Notifications className={classes.icons} />
          <span className={classes.notifications}>5</span>
          <Hidden mdUp implementation="css">
            <p onClick={handleCloseNotification} className={classes.linkText}>
              Notification
            </p>
          </Hidden>
        </Button>
        <Poppers
          open={Boolean(openNotification)}
          anchorEl={openNotification}
          transition
          disablePortal
          className={
            classNames({ [classes.popperClose]: !openNotification }) +
            " " +
            classes.popperNav
          }
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="notification-menu-list-grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom"
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleCloseNotification}>
                  <MenuList role="menu">
                    <MenuItem
                      onClick={handleCloseNotification}
                      className={classes.dropdownItem}
                    >
                      Mike John responded to your email
                    </MenuItem>
                    <MenuItem
                      onClick={handleCloseNotification}
                      className={classes.dropdownItem}
                    >
                      You have 5 new tasks
                    </MenuItem>
                    <MenuItem
                      onClick={handleCloseNotification}
                      className={classes.dropdownItem}
                    >
                      You{"'"}re now friend with Andrew
                    </MenuItem>
                    <MenuItem
                      onClick={handleCloseNotification}
                      className={classes.dropdownItem}
                    >
                      Another Notification
                    </MenuItem>
                    <MenuItem
                      onClick={handleCloseNotification}
                      className={classes.dropdownItem}
                    >
                      Another One
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Poppers>
      </div> */}
      <div className={classes.manager}>
        <Button
          color={window.innerWidth > 959 ? 'transparent' : 'white'}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-owns={openProfile ? 'profile-menu-list-grow' : null}
          aria-haspopup='true'
          onClick={handleClickProfile}
          className={classes.buttonLink}
        >
          {user && (
            // <Hidden mdDown implementation='css'>
            <Avatar
              name={
                user.alias.charAt(0).toUpperCase() + user.alias.substring(1)
              }
              size='50'
              round={true}
              color={'#00acc1'}
            />
            // </Hidden>
          )}
          {user && (
            <Hidden mdUp implementation='css'>
              <p className={classes.linkText}>
                {' '}
                {user.alias.charAt(0).toUpperCase() + user.alias.substring(1)}
              </p>
            </Hidden>
          )}
        </Button>
        <Poppers
          open={Boolean(openProfile)}
          anchorEl={openProfile}
          transition
          disablePortal
          className={
            classNames({ [classes.popperClose]: !openProfile }) +
            ' ' +
            classes.popperNav
          }
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id='profile-menu-list-grow'
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom'
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleCloseProfile}>
                  <MenuList role='menu'>
                    <Tooltip
                      onClick={() => {
                        copyMessage(user.publicKey)
                        enqueueSnackbar('Alias Copied', {
                          variant: 'info'
                        })
                        handleCloseProfile()
                      }}
                      placement='left'
                      title='Click to Copy Alias'
                    >
                      <MenuItem
                        color={'info'}
                        onClick={() => {
                          copyMessage(user.alias)
                          enqueueSnackbar('Alias Copied', {
                            variant: 'info'
                          })
                          handleCloseProfile()
                        }}
                        className={classes.dropdownItem}
                      >
                        {user.alias}
                      </MenuItem>
                    </Tooltip>
                    <Tooltip placement='left' title='Click to View Credentials'>
                      <MenuItem
                        onClick={() => {
                          props.history.push('/credentials')
                        }}
                        className={classes.dropdownItem}
                      >
                        Credentials
                      </MenuItem>
                    </Tooltip>
                    <Tooltip placement='left' title='Click to View Balance'>
                      <MenuItem
                        onClick={() => {
                          props.history.push('/balance')
                        }}
                        className={classes.dropdownItem}
                      >
                        Balance
                      </MenuItem>
                    </Tooltip>
                    {/* <MenuItem
                      onClick={() => {
                        handleCloseProfile()
                      }}
                      className={classes.dropdownItem}
                    >
                      Settings
                    </MenuItem> */}
                    <Divider light />
                    <Tooltip placement='left' title='Click to Logout'>
                      <MenuItem
                        onClick={logout}
                        className={classes.dropdownItem}
                      >
                        Logout
                      </MenuItem>
                    </Tooltip>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Poppers>
      </div>
    </div>
  )
}
export default withRouter(AdminNavbarLinks)
