import Web3 from 'web3'

//dev
// export var niftronUserLambda = "http://localhost:7000/api/v1";
export var niftronUserLambda = "https://8gq4vhf2j3.execute-api.us-east-1.amazonaws.com/dev";
// export var niftronTokenLambda = "http://localhost:7001/api/v1";
export var niftronTokenLambda = "https://rdty586fta.execute-api.us-east-1.amazonaws.com/dev";
export var NiftronAPI = 'https://dev-api.niftron.com'
export var StellarUrlTest= "https://horizon-testnet.stellar.org"
export var StellarUrl= "https://horizon.stellar.org"
export var StellarNetwork="TestNet"
export var Version="0.01"
export var NiftronIssuerPublicKey="GBIV5P2STT7M7633B4IU54MUFBKWELUX5J7ELA5QBXUGRXM4MQYKJHE7"
export var NiftronDistributorPublicKey="GCC4PL7FJJ74DGVAU3ODHU4HPU7E6CNQ6XMMV3CKEC4GFB4QG3W4L7ZF"

//prod
// // export var niftronUserLambda = "http://localhost:7000/api/v1";
// export var niftronUserLambda =
//   'https://32o47h3pc2.execute-api.us-east-1.amazonaws.com/prod'
// // export var niftronTokenLambda = "http://localhost:7001/api/v1";
// export var niftronTokenLambda =
//   'https://y548z5pqmi.execute-api.us-east-1.amazonaws.com/prod'
// export var NiftronAPI = 'https://api.niftron.com'
// export var StellarUrlTest = 'https://horizon-testnet.stellar.org'
// export var StellarUrl = 'https://horizon.stellar.org'
// export var StellarNetwork = 'TestNet'
// export var Version = '0.01'
// export var NiftronIssuerPublicKey =
//   'GBIV5P2STT7M7633B4IU54MUFBKWELUX5J7ELA5QBXUGRXM4MQYKJHE7'
// export var NiftronDistributorPublicKey =
//   'GCC4PL7FJJ74DGVAU3ODHU4HPU7E6CNQ6XMMV3CKEC4GFB4QG3W4L7ZF'

export const web3Providers = {
  providerBscTestNet: new Web3.providers.HttpProvider(
    'https://data-seed-prebsc-1-s1.binance.org:8545/'
  ),
  providerRinkeby: new Web3.providers.HttpProvider(
    'https://rinkeby.infura.io/v3/1ae5799b9f6c4321951ad280f2b82a0f'
  ),
  providerBsc: new Web3.providers.HttpProvider(
    'https://bsc-dataseed1.ninicoin.io'
  ),
  providerEthereum: new Web3.providers.HttpProvider(
    'https://mainnet.infura.io/v3/1ae5799b9f6c4321951ad280f2b82a0f'
  )
}

export const contracts = {
  ETHEREUM: {
    EXIPTLD: '',
    EXIPDOMAIN: '',
    EXIPTOKEN: ''
  },
  RINKEBY: {
    EXIPTLD: '0x2f3e95204578fa1B3F5bf9EabC92E9A2eb5fA2aD',
    EXIPDOMAIN: '0x384C818e7EfF21942C0150f2EB484723295cD9eE',
    EXIPTOKEN: '0xB01A149eb95db0E434d13498eE0F3A0d0239D34F'
  },
  BSC: {
    EXIPTLD: '0x239F16F0F107C0AfCB39cF1a9d57Be2318C1A409',
    EXIPDOMAIN: '0x3280d8E1d5b055542d1367A440c375e54390b13D',
    EXIPTOKEN: '0xB17A39F8177894AA66a706CAfEcb85EE3ECf504D'
  },
  BSCTESTNET: {
    EXIPTLD: '0x0085cdF913D2fD3B2902b694B444FE0310432338',
    EXIPDOMAIN: '0x5A4F34f21239F87f882B2f31385e1990B22486be',
    EXIPTOKEN: '0xc79A6AE6EC40B6ba364987Ae43726050788A5679'
  }
}


export const projectIssuers = {
  DOMAIN:"GCY3Z3K5GHMGR3WG6VNING6XZ457BISHCEXA6222U7PP4MPIKTXJKQKY",
  TLD: "GD2ZZUZ7ARMPOFLTCLVOWWQ4ZTOGLRYBD43RJI7JXMRIA7P66KYWED5P"
}