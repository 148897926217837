import React, { useState } from 'react'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import InputAdornment from '@material-ui/core/InputAdornment'
import Icon from '@material-ui/core/Icon'
import sha256 from 'sha256'
import Grid from '@material-ui/core/Grid'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Button from 'components/CustomButtons/Button.js'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardFooter from 'components/Card/CardFooter.js'
import CustomInput from 'components/CustomInput/CustomInput.js'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dropzone from 'react-dropzone'
import { Link } from 'react-router-dom'
import { getUserSession, decryptSecret } from 'services/UserManagement'
import LinearProgress from '@material-ui/core/LinearProgress'
import { useSnackbar } from 'notistack'
import { crossExchange } from 'services/TransferManagement'
import { BuildStellarBurn, signXDR } from 'services/XDRBuilder'
import styles from 'assets/jss/material-kit-react/views/loginPage.js'
import { Keypair } from 'stellar-sdk'
import PublicIcon from '@material-ui/icons/Public'
import CustomInputSelect from 'components/CustomInputSelect/CustomInputSelect'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { contracts, projectIssuers } from 'variables/constants'
import axios from 'axios'

const ERC721ABI = require('./ERC721.json')

const useStyles = makeStyles(styles)

function CrossExhangeTokenComponent (props) {
  const [cardAnimaton, setCardAnimation] = React.useState('cardHidden')
  setTimeout(function () {
    setCardAnimation('')
  }, 400)
  const classes = useStyles()
  const [user, setUser] = useState(getUserSession())
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = React.useState(false)
  //cross
  const [fromBlockchain, setFromBlockchain] = useState(
    props.item.blockchain ? props.item.blockchain : 'STELLAR'
  )
  const [toBlockchain, setToBlockchain] = useState('')
  const [toAccount, setToAccount] = useState(
    user.accounts.length >= 3 ? user.accounts[2].publicKey : ''
  )

  const [loadingMessage, setloadingMessage] = useState('please remain patient')
  //Confirm
  const [secret, setSecret] = useState('')
  //Confirm error
  const [secretError, SecretKeyError] = useState(false)
  const [fileError, setFileError] = useState(false)
  //hover and show
  const [hoverPassword, setHoverPassword] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [linkStyle, setLinkStyle] = useState({ cursor: 'pointer' })
  //cross Errors
  const [toBlockchainError, setToBlockchainError] = useState(false)
  const [toAccountError, setToAccountError] = useState(false)

  const { ...rest } = props
  const { enqueueSnackbar } = useSnackbar()
  const handleClose = () => {
    setOpen(false)
  }
  const handleOpen = () => {
    setOpen(true)
  }
  const exchange = async event => {
    event.preventDefault()
    //required check
    setToBlockchainError(toBlockchain === '' ? true : false)
    setToAccountError(toAccount === '' ? true : false)
    // }
    if (toBlockchain != '' && toAccount != '') {
      setLoading(true)
      setOpen(false)
      let secretKey = ''
      if (user.authType !== '2') {
        secretKey = decryptSecret(user.encryptedSecret, sha256(secret))
      }
      if (user.authType === '2') {
        secretKey = secret.toUpperCase()
      }
      if (
        !secretKey ||
        secretKey == '' ||
        secretKey == null ||
        secretKey == undefined
      ) {
        enqueueSnackbar('Invalid Credential', { variant: 'warning' })
      } else {
        const keypair = Keypair.fromSecret(secretKey)

        let xdr
        let rejectXdr
        // let fundXdr;
        try {
          const { xdrs } = await BuildStellarBurn(
            user.publicKey,
            props.item.assetIssuer,
            props.item.assetCode,
            props.item.assetCount
          )
          setloadingMessage('Signing XDR')
          // let expireXdr;
          if (xdrs != null && xdrs.length > 0) {
            await Promise.all(
              xdrs.map(async (item, index, array) => {
                xdrs[index].xdr = await signXDR(item.xdr, keypair.secret())
              })
            )
            xdr = xdrs[0]?.xdr
          }

          console.log(xdr)
        } catch (e) {
          enqueueSnackbar('Invalid Network', { variant: 'warning' })
          return
        }
        setloadingMessage('Submitting Modifications to IPFS')

        const ipfsData = await getIPFSData(
          `https://ipfs.io/ipfs/${props.item.ipfsHash}`
        )
        ipfsData.previousChain = fromBlockchain
        ipfsData.previousTokenId = props.item.tokenId
        const ipfsHash = await AddToIPFS(JSON.stringify(ipfsData))
        setloadingMessage('Submitting Exchange Request to Niftron')

        let contractId = ''
        switch (toBlockchain) {
          case 'BSC':
            contractId =
              props.item.assetIssuer == projectIssuers.TLD
                ? contracts.BSC.EXIPTLD
                : contracts.BSC.EXIPDOMAIN
            break
          case 'BSCTESTNET':
            contractId =
              props.item.assetIssuer == projectIssuers.TLD
                ? contracts.BSCTESTNET.EXIPTLD
                : contracts.BSCTESTNET.EXIPDOMAIN
            break
          case 'ETHEREUM':
            contractId =
              props.item.assetIssuer == projectIssuers.TLD
                ? contracts.ETHEREUM.EXIPTLD
                : contracts.ETHEREUM.EXIPDOMAIN
            break
          case 'RINKEBY':
            contractId =
              props.item.assetIssuer == projectIssuers.TLD
                ? contracts.RINKEBY.EXIPTLD
                : contracts.RINKEBY.EXIPDOMAIN
            break
        }
        const response = await crossExchange({
          crossType: fromBlockchain + 'TO' + toBlockchain,
          sender: user.publicKey,
          assetCode: props.item.assetCode,
          assetIssuer: props.item.assetIssuer,
          assetCount: props.item.assetCount,
          tokenName: props.item.tokenName,
          previewUrl: props.item.previewUrl,
          contractId,
          contract: ERC721ABI,
          toAccount,
          fromBlockchain: props.item.blockchain
            ? props.item.blockchain
            : 'STELLAR',
          toBlockchain,
          xdr,
          ipfsHash: ipfsHash ? ipfsHash : ''
        })
        switch (response) {
          case 200:
            enqueueSnackbar('Successfully Submitted', { variant: 'success' })
            break
          case 201:
            enqueueSnackbar('User Not Found', { variant: 'success' })
            break
          case 202:
            enqueueSnackbar('Token Not Found', { variant: 'success' })
            break
          case 203:
            enqueueSnackbar('Insufficient Fund in Account', {
              variant: 'success'
            })
            break
          case 400:
            enqueueSnackbar('Failed', { variant: 'success' })
            break
          case null:
            enqueueSnackbar('Failed', { variant: 'success' })
        }
      }

      //   enqueueSnackbar('Exchange initiated', { variant: 'success' })
      setLoading(false)
    }
  }
  const confirmAction = e => {
    e.preventDefault()
    setToBlockchainError(toBlockchain === '' ? true : false)
    setToAccountError(toAccount === '' ? true : false)
    //   var re = /^\d+$/
    //   setAssetCountError(assetCount === '' || !re.test(assetCount) ? true : false)
    //   setReceiversError(receivers.length === 0 ? true : false)
    if (toBlockchain != '' && toAccount != '') {
      handleOpen()
    }
  }
  const AddToIPFS = async stringToUse => {
    const formData = new FormData()
    formData.append('base64', stringToUse)
    const res = await axios.post(
      'https://ipfs.infura.io:5001/api/v0/add',
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
    )
    if (res === null) {
      return null
    }
    return res.data.Hash
  }

  const getIPFSData = async url => {
    try {
      const res = await axios.get(url)
      if (res === null) {
        throw Error('Failed to add data to IPFS')
      }
      return res.data
    } catch (er) {
      console.log(er)
      return null
    }
  }
  return (
    <div className={classes.container}>
      <Dialog
        maxWidth='sm'
        open={open}
        onClose={handleClose}
        aria-labelledby='responsive-dialog-title'
        scroll={'body'}
      >
        <DialogTitle id='responsive-dialog-title'>
          {'Please Confirm Action'}
        </DialogTitle>
        <DialogContent dividers>
          <form
            style={
              loading
                ? {
                    filter: 'blur(1px)',
                    '-webkit-filter': 'blur(1px)'
                  }
                : null
            }
            onSubmit={exchange}
          >
            <CustomInput
              error={secretError}
              labelText={user.authType === '2' ? 'Secret Key *' : 'Password *'}
              id='tokenName'
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                type: !showPassword ? 'password' : 'text',
                endAdornment: (
                  <InputAdornment position='end'>
                    {hoverPassword && (
                      <Icon
                        style={linkStyle}
                        onMouseEnter={() => {
                          setHoverPassword(true)
                        }}
                        onMouseLeave={() => {
                          setHoverPassword(false)
                        }}
                        className={classes.inputIconsColor}
                        onClick={() => {
                          setShowPassword(!showPassword)
                        }}
                      >
                        {showPassword ? 'visibilityoff' : 'visibility'}
                      </Icon>
                    )}
                    {!hoverPassword && (
                      <Icon
                        style={linkStyle}
                        className={classes.inputIconsColor}
                        onMouseEnter={() => {
                          setHoverPassword(true)
                        }}
                        onMouseLeave={() => {
                          setHoverPassword(false)
                        }}
                      >
                        lock
                      </Icon>
                    )}
                  </InputAdornment>
                ),
                required: true,
                onChange: function (e) {
                  setSecret(e.target.value)
                  SecretKeyError(e.target.value === '' ? true : false)
                },
                value: secret
              }}
            />
            {user.authType === '2' && (
              <>
                <Grid
                  container
                  spacing={0}
                  direction='column'
                  alignItems='center'
                  justify='center'
                >
                  <Grid item xs={3}>
                    or{' '}
                  </Grid>
                </Grid>
                <Dropzone
                  onDrop={acceptedFiles => {
                    const reader = new FileReader()
                    reader.addEventListener('load', async () => {
                      // setFile(reader.result)
                      setSecret(JSON.parse(atob(reader.result)).secretKey)
                    })
                    reader.readAsText(acceptedFiles[0])
                  }}
                  multiple={false}
                  accept={['.niftron']}
                >
                  {({ getRootProps, getInputProps }) => (
                    <Link>
                      <section className={'container'}>
                        <div
                          {...getRootProps({
                            className: fileError
                              ? 'dropzone2Error'
                              : 'dropzone2'
                          })}
                        >
                          <input {...getInputProps()} />
                          <p>Drop or select your niftron credential file</p>
                        </div>
                      </section>
                    </Link>
                  )}
                </Dropzone>
              </>
            )}
            <Button
              color={'info'}
              onClick={exchange}
              type={'submit'}
              disabled={loading}
            >
              Confirm
            </Button>
          </form>
        </DialogContent>
      </Dialog>
      <Card>
        <form className={classes.form} onSubmit={confirmAction}>
          <CardHeader color='info' className={classes.cardHeader}>
            <h3>Cross Exchange</h3>
          </CardHeader>
          <CardBody>
            <div
              style={
                loading
                  ? {
                      filter: 'blur(1px)',
                      '-webkit-filter': 'blur(1px)'
                    }
                  : null
              }
            >
              <GridContainer justify='center'>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    color='info'
                    // error={assetCountError}
                    labelText='From Blockchain'
                    id='fromBlockchain'
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: 'text',
                      required: true,
                      value: fromBlockchain,
                      disabled: props.item.tokenType === 'NFT' ? false : true
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  <CustomInputSelect
                    //info
                    error={toBlockchainError}
                    options={
                      <Select
                        // disabled
                        value={toBlockchain}
                        onChange={e => {
                          setToBlockchain(e.target.value)
                          setToBlockchainError(
                            e.target.value === '' ? true : false
                          )
                          if (e.target.value == 'STELLAR') {
                            setToAccount(user.publicKey ? user.publicKey : '')
                          } else {
                            setToAccount(
                              user.accounts.length >= 3
                                ? user.accounts[2].publicKey
                                : ''
                            )
                          }
                        }}
                        displayEmpty
                        className={classes.selectEmpty}
                      >
                        <MenuItem value=''>
                          <em>Choose Your Blockchain</em>
                        </MenuItem>

                        <MenuItem value={'BSC'}>BSC</MenuItem>
                        <MenuItem value={'ETHEREUM'} disabled>
                          ETHEREUM
                        </MenuItem>
                        {/* <MenuItem value={'BSCTESTNET'}>BSCTESTNET</MenuItem>

                        <MenuItem value={'RINKEBY'}>RINKEBY</MenuItem> */}
                      </Select>
                    }
                    labelText='To Blockchain *'
                    id='blockchain'
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: 'text',
                      endAdornment: (
                        <InputAdornment position='end'>
                          <PublicIcon className={classes.inputIconsColor} />
                        </InputAdornment>
                      ),
                      required: true
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    color='info'
                    error={toAccountError}
                    labelText='To Account'
                    id='assetCount'
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: 'text',
                      required: true,
                      value: toAccount,
                      onChange: function (e) {
                        setToAccount(e.target.value)
                        var re = /^\d+$/
                        //required check
                        setToAccountError(e.target.value === '' ? true : false)
                      }
                      //   disabled: props.item.tokenType === 'NFT' ? false : true
                    }}
                  />
                </GridItem>
              </GridContainer>
            </div>
            {loading && (
              <>
                {loadingMessage}
                <LinearProgress />
              </>
            )}
          </CardBody>
          <CardFooter className={classes.cardFooter}>
            <Button
              color='info'
              size='lg'
              type={'submit'}
              //   onClick={confirmAction}
              disabled={loading || toAccountError || toBlockchainError}
            >
              Exchange
            </Button>
          </CardFooter>
        </form>
      </Card>
    </div>
  )
}

export default CrossExhangeTokenComponent
