/* eslint-disable */
import React, { useState, useEffect } from "react";
// react plugin for creating charts
// import ChartistGraph from "react-chartist";
// @material-ui/core
// import Icon from "@material-ui/core/Icon";
// @material-ui/icons
// import Store from "@material-ui/icons/Store";
// import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
// import LocalOffer from "@material-ui/icons/LocalOffer";
// import Update from "@material-ui/icons/Update";
// import ArrowUpward from "@material-ui/icons/ArrowUpward";
// import AccessTime from "@material-ui/icons/AccessTime";
// import Accessibility from "@material-ui/icons/Accessibility";
// import BugReport from "@material-ui/icons/BugReport";
// import Code from "@material-ui/icons/Code";
// import Cloud from "@material-ui/icons/Cloud";
import { CardMedia } from '@material-ui/core';

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
// import Table from "components/Table/Table.js";
// import Tasks from "components/Tasks/Tasks.js";
// import CustomTabs from "components/CustomTabs/CustomTabs.js";
// import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
// import CardHeader from "components/Card/CardHeader.js";
import CardToken from "components/Card/CardToken.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

// import Button from "components/CustomButtons/Button.js";

import { format } from 'timeago.js';

// import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import TransferCertificate from "views/Token/Transfer/TransferCertificate"

import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import axios from "axios";
import { getTransferByNidAndReceiver } from "services/TransferManagement";


import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';

import Divider from '@material-ui/core/Divider';

import { getUserSession } from "services/UserManagement";


const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        // width: 500,
    },
    paperName: {
        padding: theme.spacing(1),
        // textAlign: 'center',
        // color: theme.palette.text.secondary,
    },
    paperValue: {
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

export default function Certificate(props) {
    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = React.useState(0);
    const [user, setUser] = useState(getUserSession())

    const [transfer, setTransfer] = useState(null);

    const [ipfsData, setIpfsData] = useState(null);
    const [ipfsDataArray, setIpfsDataArray] = useState([]);

    const [ipfsTransferData, setIpfsTransferData] = useState(null);
    const [ipfsTransferDataArray, setIpfsTransferDataArray] = useState([]);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };
    const makePresentable = (text) => {
        const response = text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
        return response.length > 20 ? response.substring(0, 20) + " ..." : response
    };

    const makePresentablePk = (text) => {
        const response = text
        return response.length > 20 ? response.substring(0, 20) + " ..." : response
    };

    useEffect(() => {
        async function fetchIpfs() {
            try {
                const res = await axios.get(
                    `https://ipfs.io/ipfs/${props.item.ipfsHash}`
                );
                if (res) {
                    // console.log(res.data)
                    if (Object.keys(res.data).length === 0) {
                        setIpfsData("NA");
                    } else {
                        setIpfsData(JSON.stringify(res.data));
                        var array = [];

                        for (var i in res.data)
                            array.push({ key: i, value: res.data[i] });

                        setIpfsDataArray(array)
                    }
                    // setIpfsData(res.data)
                }
            } catch (er) {
                console.log(er);
            }
        }

        const fetchTransferData = async () => {
            let result = await getTransferByNidAndReceiver(props.item.assetCode,
                props.item.assetIssuer, user.publicKey);
            if (result != null) {
                if (result.data) {
                    console.log(result.data)
                    setTransfer(result.data);
                }
            }
        };
        fetchIpfs()
        fetchTransferData()

    }, []);

    useEffect(() => {
        async function fetchIpfs() {
            try {
                const res = await axios.get(
                    `https://ipfs.io/ipfs/${transfer.ipfsHash}`
                );
                if (res) {
                    console.log(res.data)
                    if (Object.keys(res.data).length === 0) {
                        setIpfsTransferData("NA");
                    } else {
                        setIpfsTransferData(JSON.stringify(res.data));
                        var array = [];

                        for (var i in res.data)
                            array.push({ key: i, value: res.data[i] });

                        setIpfsTransferDataArray(array)
                    }
                    // setIpfsData(res.data)
                }
            } catch (er) {
                console.log(er);
            }
        }
        fetchIpfs();
    }, [transfer]);
    return (
        <Card>

            <GridContainer>
                <GridItem xs={12} sm={12} md={6} lg={6} >
                    <Card style={{
                        maxHeight: 500,
                        overflow: "auto"
                    }} >
                        {/* <img style={{ height: "100%", width: "100%" }}
                            onContextMenu={e => {
                                e.preventDefault()
                            }}
                            src={props.item.previewUrl} /> */}
                        <CardMedia style={{ height: 500, maxHeight: 500, "background-size": "contain" }} image={props.item.previewUrl} />


                    </Card>
                </GridItem>
                <GridItem xs={12} sm={12} md={6} lg={6} >
                    <div className={classes.root}>
                        <AppBar position="static" color="default">
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="scrollable"
                                scrollButtons="auto"

                                aria-label="full width tabs example"
                            >
                                <Tab label="Details" />
                                {/* <Tab label="Issue Certificate" /> */}
                            </Tabs>
                        </AppBar>
                        <SwipeableViews
                            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                            index={value}
                            onChangeIndex={handleChangeIndex} >
                            <TabPanel value={value} index={0} dir={theme.direction}>
                                <Card>
                                    <CardBody >
                                        <Grid container spacing={1}>
                                            <Grid container item xs={12} sm={12} md={6} lg={6} spacing={3}>
                                                <ListItem >
                                                    <ListItemText primary={makePresentable(props.item.category) + " Name"} />
                                                </ListItem>
                                            </Grid>
                                            <Grid container item xs={12} sm={12} md={6} lg={6} spacing={3}>
                                                <ListItem >
                                                    <ListItemText secondary={props.item.tokenName} />
                                                </ListItem>
                                            </Grid>
                                            <Divider variant="middle" />
                                            <Grid container item item xs={12} sm={12} md={6} lg={6} spacing={3}>
                                                <ListItem >
                                                    <ListItemText primary="Quantity" />
                                                </ListItem>
                                            </Grid>
                                            <Grid container item xs={12} sm={12} md={6} lg={6} spacing={3}>
                                                <ListItem >
                                                    <ListItemText secondary={props.item.assetCount} />
                                                </ListItem>
                                            </Grid>
                                            <Divider variant="middle" />
                                            <Grid container item item xs={12} sm={12} md={6} lg={6} spacing={3}>
                                                <ListItem >
                                                    <ListItemText primary="Accredited By" />
                                                </ListItem>
                                            </Grid>
                                            <Grid container item xs={12} sm={12} md={6} lg={6} spacing={3}>
                                                <ListItem >
                                                    <ListItemText secondary={"Verify"} />
                                                </ListItem>
                                            </Grid>
                                            <Divider variant="middle" />
                                            <Grid container item xs={12} sm={12} md={6} lg={6} spacing={3}>
                                                <ListItem >
                                                    <ListItemText primary="Niftron ID" />
                                                </ListItem>
                                            </Grid>
                                            <Grid container item xs={12} sm={12} md={6} lg={6} spacing={3}>
                                                <ListItem >
                                                    <ListItemText secondary={props.item.assetCode} />
                                                </ListItem>
                                            </Grid>
                                            <Divider variant="middle" />
                                            {ipfsData && ipfsDataArray.length > 0 && ipfsDataArray.map((item, i) => (
                                                <><Grid container item xs={12} sm={12} md={6} lg={6} spacing={3}>
                                                    <ListItem >
                                                        <ListItemText primary={makePresentable(item.key)} />
                                                    </ListItem>
                                                </Grid>
                                                    <Grid container item xs={12} sm={12} md={6} lg={6} spacing={3}>
                                                        <ListItem >
                                                            <ListItemText secondary={item.value} />
                                                        </ListItem>
                                                    </Grid>
                                                    <Divider variant="middle" />
                                                </>
                                            ))
                                            }
                                            <Grid container item xs={12} sm={12} md={12} lg={12} spacing={3}>
                                                <ListItem >
                                                    <ListItemText primary={"Approved By"} />
                                                </ListItem>
                                            </Grid>
                                            {transfer && transfer.signers && transfer.signers.length > 0 && transfer.signers.map((item, i) => (
                                                <>


                                                    <Grid container item xs={6} sm={6} md={6} lg={6} spacing={3}>
                                                        <ListItem >
                                                            <ListItemText secondary={makePresentablePk(item.publicKey)} />
                                                        </ListItem>
                                                    </Grid>
                                                    <Grid container item xs={6} sm={6} md={6} lg={6} spacing={3}>
                                                        <ListItem >
                                                            <ListItemText secondary={item.status == "ACCEPTED" ? "APPROVED" : item.status} />
                                                        </ListItem>
                                                    </Grid>
                                                    <Divider variant="middle" />
                                                </>
                                            ))
                                            }
                                            {ipfsTransferData && ipfsTransferDataArray.length > 0 && ipfsTransferDataArray.map((item, i) => (
                                                <>
                                                    <Grid container item xs={6} sm={6} md={4} lg={3} spacing={3}>
                                                        <ListItem >
                                                            <ListItemText primary={makePresentable(item.key)} />
                                                        </ListItem>
                                                    </Grid>
                                                    <Grid container item xs={6} sm={6} md={8} lg={9} spacing={3}>
                                                        <ListItem >
                                                            <ListItemText secondary={item.value} />
                                                        </ListItem>
                                                    </Grid>
                                                    <Divider variant="middle" />
                                                </>
                                            ))
                                            }
                                        </Grid>
                                        {/* <GridContainer >
                                            <GridItem xs={12} sm={12} md={6} lg={6} >
                                                Name: {props.item.tokenName}
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={6} lg={6} >
                                                Niftron ID: {props.item.assetCode}
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={6} lg={6} >
                                                Quantity: {props.item.assetCount}
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={6} lg={6} >
                                                Type: {props.item.tokenType}
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={6} lg={6} >
                                                Category: {props.item.category}
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={6} lg={6} >
                                                Data: {ipfsData}
                                            </GridItem></GridContainer> */}


                                        {/* <div className={classes.root}>
                                            <Grid container spacing={1}>
                                                <Grid container item xs={12} spacing={3}>
                                                    <Grid item xs={4}>
                                                        <Paper className={classes.paperName}> Name: </Paper>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <Paper className={classes.paperValue}>{props.item.tokenName}</Paper>
                                                    </Grid>
                                                </Grid>
                                                <Grid container item xs={12} spacing={3}>
                                                    <Grid item xs={4}>
                                                        <Paper className={classes.paperName}> Niftron ID: </Paper>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <Paper className={classes.paperValue}>{props.item.assetCode}</Paper>
                                                    </Grid>
                                                </Grid>
                                                <Grid container item xs={12} spacing={3}>
                                                    <Grid item xs={4}>
                                                        <Paper className={classes.paperName}>Quantity:</Paper>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <Paper className={classes.paperValue}>{props.item.assetCount}</Paper>
                                                    </Grid>
                                                </Grid>
                                                <Grid container item xs={12} spacing={3}>
                                                    <Grid item xs={4}>
                                                        <Paper className={classes.paperName}>Type: </Paper>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <Paper className={classes.paperValue}>{props.item.tokenType}</Paper>
                                                    </Grid>
                                                </Grid>
                                                <Grid container item xs={12} spacing={3}>
                                                    <Grid item xs={4}>
                                                        <Paper className={classes.paperName}>  Category:</Paper>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <Paper className={classes.paperValue}>{props.item.category}</Paper>
                                                    </Grid>
                                                </Grid>
                                                <Grid container item xs={12} spacing={3}>
                                                    <Grid item xs={4}>
                                                        <Paper className={classes.paperName}>  Data:</Paper>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <Paper className={classes.paperValue}>{ipfsData}</Paper>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </div> */}
                                    </CardBody>
                                </Card>
                            </TabPanel>
                            <TabPanel value={value} index={1} dir={theme.direction}>
                                <TransferCertificate item={props.item} /></TabPanel>
                        </SwipeableViews>
                    </div>

                </GridItem>
            </GridContainer>

        </Card>
    );
}
