/* eslint-disable */
import React, { useState, useEffect } from "react";
// react plugin for creating charts
// import ChartistGraph from "react-chartist";
// @material-ui/core
// import Icon from "@material-ui/core/Icon";
// @material-ui/icons
// import Store from "@material-ui/icons/Store";
// import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
// import LocalOffer from "@material-ui/icons/LocalOffer";
// import Update from "@material-ui/icons/Update";
// import ArrowUpward from "@material-ui/icons/ArrowUpward";
// import AccessTime from "@material-ui/icons/AccessTime";
// import Accessibility from "@material-ui/icons/Accessibility";
// import BugReport from "@material-ui/icons/BugReport";
// import Code from "@material-ui/icons/Code";
// import Cloud from "@material-ui/icons/Cloud";
import { CardMedia } from '@material-ui/core';

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
// import Table from "components/Table/Table.js";
// import Tasks from "components/Tasks/Tasks.js";
// import CustomTabs from "components/CustomTabs/CustomTabs.js";
// import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardToken from "components/Card/CardToken.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dropzone from 'react-dropzone';
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import axios from "axios";
import { BuildClaimBadgeNFTXDR ,signXDR } from "services/XDRBuilder";

import { withRouter, Link } from "react-router-dom";

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';

import Divider from '@material-ui/core/Divider';

import { getUserSession } from "services/UserManagement";

import { AddClaim } from "services/ClaimManagement";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        // width: 500,
    },
    paperName: {
        padding: theme.spacing(1),
        // textAlign: 'center',
        // color: theme.palette.text.secondary,
    },
    paperValue: {
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

export default function Certificate(props) {
    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = React.useState(0);
    const [user, setUser] = useState(getUserSession())
    const [openDialog, setOpenDialog] = React.useState(false);

    const [loading, setLoading] = useState(false);

    const [ipfsData, setIpfsData] = useState(null);
    const [ipfsDataArray, setIpfsDataArray] = useState([]);
    const [open, setOpen] = React.useState(false);

    //Confirm 
    const [secret, setSecret] = useState("");

    //Confirm error
    const [secretError, SecretKeyError] = useState(false);
    const [fileError, setFileError] = useState(false)

    //hover and show
    const [hoverPassword, setHoverPassword] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [linkStyle, setLinkStyle] = useState({ cursor: 'pointer' });
    const { enqueueSnackbar } = useSnackbar();

    // const [ipfsTransferData, setIpfsTransferData] = useState(null);
    // const [ipfsTransferDataArray, setIpfsTransferDataArray] = useState([]);
    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };

    const makePresentable = (text) => {
        const response = text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
        return response.length > 20 ? response.substring(0, 20) + " ..." : response
    };

    const confirmAction = async (e) => {
        setLoading(true)
        setOpen(false);

        let secretKey = "";
        if (user.authType !== "2") {
            secretKey = decryptSecret(user.encryptedSecret, sha256(secret))
        }
        if (user.authType === "2") {
            secretKey = secret.toUpperCase()
        }
        if (!secretKey || secretKey == "" || secretKey == null || secretKey == undefined) {
            enqueueSnackbar("Invalid Credential", { variant: "warning" });
        } else {
            await claim(secretKey)
        }
        setLoading(false)
    }

    const claim = async (secretKey) => {
        const xdrResponse = await BuildClaimBadgeNFTXDR(
            props.item.assetIssuer,
            user.publicKey,
            props.item.assetIssuer,
            props.item.assetCode,
            1
        )

        if (xdrResponse == null) {
            enqueueSnackbar("Claim builder failed", { variant: "error" });
        }

        switch (xdrResponse.code) {
            case 201: return enqueueSnackbar("Merchant Not Found", { variant: "warning" }); break;
            case 202: return enqueueSnackbar("User Not Found", { variant: "error" }); break;
            case 203: return enqueueSnackbar("Claims Limit Reached", { variant: "warning" }); break;
            case 204: return enqueueSnackbar("Already Claimed", { variant: "warning" }); break;
            case null: return enqueueSnackbar("Login Failed", { variant: "error" });
        }

        let xdr = ""

        if (xdrResponse.data != null && xdrResponse.data.length > 0) {
            await Promise.all(
                xdrResponse.data.map(async (item, index, array) => {
                    xdrResponse.data[index].xdr = await signXDR(item.xdr, secretKey);
                })
            );
            xdr = xdrResponse.data[0]?.xdr
        }
        console.log(xdr)

        const response = await AddClaim({
            sender: props.item.assetIssuer,
            receiver: user.publicKey,
            assetCode: props.item.assetCode,
            assetIssuer: props.item.assetIssuer,
            assetCount: 1,
            xdr: xdr,
        })
        if (response != null) {
            switch (response) {
                case 200: enqueueSnackbar("Claimed Successfully", { variant: "success" });

                    props.history.push("/");
                     break;
                case 201: enqueueSnackbar("Account Not Found", { variant: "warning" }); break;
                case 202: enqueueSnackbar("Token Not Found", { variant: "warning" }); break;
                case 203: enqueueSnackbar("Insufficient Fund", { variant: "warning" }); break;

                case null: enqueueSnackbar("Claim Failed", { variant: "error" });

            }
        }

        setLoading(false)
    }

    useEffect(() => {
        async function fetchIpfs() {
            try {
                const res = await axios.get(
                    `https://ipfs.io/ipfs/${props.item.ipfsHash}`
                );
                if (res) {
                    // console.log(res.data)
                    if (Object.keys(res.data).length === 0) {
                        setIpfsData("NA");
                    } else {
                        setIpfsData(JSON.stringify(res.data));
                        var array = [];

                        for (var i in res.data)
                            array.push({ key: i, value: res.data[i] });

                        setIpfsDataArray(array)
                    }
                    // setIpfsData(res.data)
                }
            } catch (er) {
                console.log(er);
            }
        }
        fetchIpfs()
    }, []);

    return (
        <Card>
            <Dialog
                maxWidth="sm"
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                scroll={"body"}
            >
                <DialogTitle id="responsive-dialog-title">{"Please Confirm Action"}</DialogTitle>
                <DialogContent dividers>
                    <form style={loading ? {
                        filter: "blur(1px)",
                        "-webkit-filter": "blur(1px)"
                    } : null} onSubmit={(event) => {
                        event.preventDefault()
                        confirmAction()
                    }}>
                        <CustomInput
                            error={secretError}
                            labelText={user.authType === "2" ? "Secret Key *" : "Password *"}
                            id="tokenName"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                type: !showPassword ? "password" : "text",
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {hoverPassword &&
                                            <Icon style={linkStyle} onMouseEnter={() => { setHoverPassword(true) }} onMouseLeave={() => { setHoverPassword(false) }}
                                                className={classes.inputIconsColor} onClick={() => {
                                                    setShowPassword(!showPassword)
                                                }}>
                                                {showPassword ? "visibilityoff" : "visibility"}</Icon>
                                        }
                                        {!hoverPassword &&
                                            <Icon style={linkStyle} className={classes.inputIconsColor}
                                                onMouseEnter={() => { setHoverPassword(true) }} onMouseLeave={() => { setHoverPassword(false) }}>
                                                lock</Icon>
                                        }
                                    </InputAdornment>
                                ), required: true,
                                onChange: function (e) {
                                    setSecret(e.target.value)
                                    SecretKeyError(e.target.value === "" ? true : false)
                                }, value: secret
                            }}
                        />
                        {user.authType === "2" && <>
                            <Grid
                                container
                                spacing={0}
                                direction="column"
                                alignItems="center"
                                justify="center"
                            >
                                <Grid item xs={3}>or</Grid>
                            </Grid>
                            <Dropzone onDrop={acceptedFiles => {
                                const reader = new FileReader()
                                reader.addEventListener("load", async () => {
                                    // setFile(reader.result)
                                    setSecret(JSON.parse(atob(reader.result)).secretKey)
                                });
                                reader.readAsText(acceptedFiles[0])
                            }}
                                multiple={false} accept={[".niftron"]}>
                                {({ getRootProps, getInputProps }) => (
                                    <Link >
                                        <section className={"container"}>
                                            <div {...getRootProps({ className: fileError ? 'dropzone2Error' : 'dropzone2' })}>
                                                <input {...getInputProps()} />
                                                <p>Drop or select your niftron credential file</p>
                                            </div>
                                        </section>
                                    </Link>
                                )}
                            </Dropzone></>
                        }
                        <Grid container className={classes.root} spacing={2}>
                            <Grid item xs={12}>
                                <Grid container justify="center" spacing={2}>
                                    <Grid item>
                                        <Button color={"info"} onClick={(event) => {
                                            event.preventDefault()
                                            confirmAction()
                                        }}
                                            type={"submit"} disabled={loading}>Confirm</Button>
                                    </Grid>

                                </Grid>
                            </Grid>
                        </Grid>

                    </form>
                </DialogContent>
            </Dialog>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={12} >
                    {props.item.assetCount != 0 ? <SnackbarContent
                        message={"Your In Luck! Scroll Down To Claim Your Badge"}
                        icon={AddAlert}
                        color="success"
                    />
                        : <SnackbarContent
                            message={"Tough Luck Mate, Early Bird Wins."}
                            icon={AddAlert}
                            color="danger"
                        />}
                </GridItem>

                <GridItem xs={12} sm={6} md={4} lg={3} >
                    <Card style={{
                        maxHeight: 500,
                        overflow: "auto"
                    }} >
                        <CardMedia style={{ height: 500, maxHeight: 500, "background-size": "contain" }} image={props.item.previewUrl} />
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={8} lg={9} >
                    <div className={classes.root}>
                        <Card>
                            <CardBody >
                                <Grid container spacing={1}>
                                    <Grid container item xs={12} sm={12} md={6} lg={6} spacing={3}>
                                        <ListItem >
                                            <ListItemText primary={makePresentable(props.item.category) + " Name"} />
                                        </ListItem>
                                    </Grid>
                                    <Grid container item xs={12} sm={12} md={6} lg={6} spacing={3}>
                                        <ListItem >
                                            <ListItemText secondary={props.item.tokenName} />
                                        </ListItem>
                                    </Grid>

                                    <Divider variant="middle" />
                                    <Grid container item xs={12} sm={12} md={6} lg={6} spacing={3}>
                                        <ListItem >
                                            <ListItemText primary="Niftron ID" />
                                        </ListItem>
                                    </Grid>
                                    <Grid container item xs={12} sm={12} md={6} lg={6} spacing={3}>
                                        <ListItem >
                                            <ListItemText secondary={props.item.assetCode} />
                                        </ListItem>
                                    </Grid>
                                    <Divider variant="middle" />
                                    <Grid container item item xs={12} sm={12} md={6} lg={6} spacing={3}>
                                        <ListItem >
                                            <ListItemText primary="Available" />
                                        </ListItem>
                                    </Grid>
                                    <Grid container item xs={12} sm={12} md={6} lg={6} spacing={3}>
                                        <ListItem >
                                            <ListItemText secondary={props.item.assetCount} />
                                        </ListItem>
                                    </Grid>
                                    <Divider variant="middle" />
                                    {ipfsData && ipfsDataArray.length > 0 && ipfsDataArray.map((item, i) => (
                                        <><Grid container item xs={12} sm={12} md={6} lg={6} spacing={3}>
                                            <ListItem >
                                                <ListItemText primary={makePresentable(item.key)} />
                                            </ListItem>
                                        </Grid>
                                            <Grid container item xs={12} sm={12} md={6} lg={6} spacing={3}>
                                                <ListItem >
                                                    <ListItemText secondary={item.value} />
                                                </ListItem>
                                            </Grid>
                                            <Divider variant="middle" />
                                        </>
                                    ))
                                    }
                                </Grid>
                            </CardBody>
                            <CardFooter className={classes.cardFooter}>
                                <Grid container className={classes.root} spacing={2}>
                                    <Grid item xs={12}>
                                        <Grid container justify="center" spacing={2}>
                                            <Grid item>
                                                <Button color="info" size="lg" type={"submit"} onClick={(e) => {
                                                    e.preventDefault()
                                                    handleOpen()

                                                }} >
                                                    Claim Badge</Button>
                                            </Grid>

                                        </Grid>
                                    </Grid>
                                </Grid>
                            </CardFooter>
                        </Card>
                    </div>

                </GridItem>
            </GridContainer>

        </Card>
    );
}
